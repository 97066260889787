import { Fade } from "@material-ui/core";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";
import "./styles.css";

export default function NewChamado({
  chamados = [],
  visible,
  header,
  onHide,
  chamado,
  setToast,
  setChamado,
  reload = null,
  setLoading = null,
  call = false,
  empresas,
  tecnicos
}) {
  const { access_type } = useAuth();

  let [maquinaParada, setMaquinaParada] = useState("Nao");
  let [nomeEmpresa, setNomeEmpresa] = useState("");
  let [status, setStatus] = useState("Aberto");
  let [servico, setServico] = useState("");
  let [horaDoChamadoCliente, setHoraDoChamadoCliente] = useState(new Date());
  let [dataDoChamado, setDataDoChamado] = useState(new Date());
  let [descricaoDoProblema, setDescricaoDoProblema] = useState("");
  let [horaDoAvisoTecnico, setHoraDoAvisoTecnico] = useState("");
  let [tecnico, setTecnico] = useState("");
  let [relatorio, setRelatorio] = useState("");
  let [dataDoAtendimento, setDataDoAtendimento] = useState("");
  let [horaDeAtendimento, setHoraDeAtendimento] = useState("");
  let [observacoes, setObservacoes] = useState("");
  let [id_empresa, setid_Empresa] = useState("");
  let [tecnico_id, setTecnico_id] = useState(null);

  const [cancelJustification, setCancelJustification] = useState("");

  const [is_rework, setIsRework] = useState(false);
  const [rework_id, setReworkId] = useState(null);
  const [rework, setRework] = useState(null);

  const [disableStatus, setDisableStatus] = useState(true);

  let [marcas, setMarcas] = useState([]);
  let [modelos, setModelos] = useState([]);

  const [disableEquipment, setDisableEquipment] = useState(true);

  const [equipments, setEquipments] = useState([]);

  const constructJavascriptTime = (time) => {
    if (time) {
      const splited = time.split(":");
      let date = new Date(new Date().setHours(splited[0]));
      date = new Date(date.setMinutes(splited[1]));
      return date;
    }
  };

  const [chamadosAll, setChamadosAll] = useState([]);
  const [page, setPage] = useState(1);
  const [chamadosRework, setChamadosRework] = useState([
    { value: "", label: "" }
  ]);

  const getChamados = async (page = 1) => {
    if (access_type !== "Admin") return;

    const response = await api.get(
      `service_calls/all?page=${page}&is_dropdown=true`
    );

    // const filteredChamadoWithNoName = response.data.filter(
    //   (chamado) => !chamado.company?.name
    // );

    setChamadosRework(
      response.data.map((chamado) => ({
        value: chamado.id,
        label:
          renderServiceNumber(chamado) +
          " | " +
          (chamado?.company?.name ?? "Não informado")
      }))
    );
  };

  useEffect(() => {
    getChamados();
  }, []);

  useEffect(() => {
    if (
      Object.keys(chamado).length !== 0 &&
      (chamado.edited || chamado.aprovar)
    ) {
      setEditValues(chamado);

      if (chamado.aprovar && chamado?.created_by?.user_type === "Tech") {
        setTecnico_id(chamado?.created_by?.id);
        setTecnico(chamado?.created_by?.name);
      }
    } else {
      applyDefaultValues();
    }
  }, [chamado, call]);

  const setEditValues = (chamado) => {
    setMaquinaParada(chamado.machine_stopped === 1 ? "Sim" : "Nao");
    setNomeEmpresa(chamado?.company?.name);
    setid_Empresa(chamado?.company?.id);
    setStatus(chamado.status);
    setServico(chamado.service_type);
    setHoraDoChamadoCliente(
      constructJavascriptTime(chamado.costumer_created_time)
    );
    setDataDoChamado(constructJavascriptDate(chamado.service_date));
    setDescricaoDoProblema(chamado.error_description);
    setHoraDoAvisoTecnico(constructJavascriptTime(chamado.tech_warn_time));
    setTecnico_id(chamado?.tech?.id);
    setTecnico(chamado?.tech?.name);
    setRelatorio(chamado.report_number);
    setDataDoAtendimento(
      constructJavascriptDate(chamado.costumer_service_date)
    );
    setHoraDeAtendimento(
      constructJavascriptTime(chamado.costumer_service_time)
    );
    setObservacoes(chamado.comments);
    setEquipment(chamado?.equipment?.equipment);
    setEquipment_id(chamado?.equipment?.id);
    setDisableEquipment(false);
    setDisableStatus(false);
    if (chamado.is_rework && chamado.rework_id) {
      setIsRework(chamado.is_rework === 1 ? true : false);
      setReworkId(chamado.rework_id ?? null);
      setRework(
        renderServiceNumber(chamado.rework) +
          " | " +
          chamado?.rework?.company?.name
      );
    }
    setCancelJustification(chamado.cancel_justification);
  };

  const applyDefaultValues = () => {
    setNomeEmpresa("");
    setid_Empresa("");
    setServico("");
    setDescricaoDoProblema("");
    setHoraDoAvisoTecnico("");
    setTecnico("");
    setRelatorio("");
    setDataDoAtendimento("");
    setHoraDeAtendimento("");
    setObservacoes("");
    setEquipment("");
    setEquipment_id("");
    setDisableEquipment(true);
    setMaquinaParada("Nao");
    setStatus("Aberto");
    setHoraDoChamadoCliente(new Date());
    setDataDoChamado(new Date());
    setDisableStatus(true);
    setTecnico_id(null);
    setIsRework(false);
    setReworkId(null);
    setRework(null);
    setCancelJustification("");
  };

  const handleChangeSelect = async (selectedOption) => {
    setEquipment_id("");
    setEquipment("");
    setDisableEquipment(true);

    setid_Empresa(selectedOption.value);
    options.forEach((e) => {
      if (e.value === selectedOption.value) {
        setNomeEmpresa(e.label);
        return;
      }
    });

    await getEquipments(selectedOption.value);
    setDisableEquipment(false);
  };

  const handleChangeSelectTecnico = (selectedOption) => {
    setTecnico_id(selectedOption.value);
    options2.forEach((e) => {
      if (e.value === selectedOption.value) {
        setHoraDoAvisoTecnico(new Date());
        setTecnico(e.label);
        return;
      }
    });
  };

  const handleChangeSelectRework = (selectedOption) => {
    setReworkId(selectedOption.value);
    chamadosRework.forEach((e) => {
      if (e.value === selectedOption.value) {
        setRework(e.label);
        const chamado = chamadosAll.find((chamado) => chamado.id === e.value);

        if (!chamado) return;

        setEditValues(chamado);

        return;
      }
    });
  };

  const [equipment_id, setEquipment_id] = useState(0);
  const [equipment, setEquipment] = useState("");

  const [selectEquipments, setSelectEquipments] = useState([
    { value: "", label: "", servico: "" }
  ]);

  useEffect(() => {
    getEquipments(id_empresa);
  }, [chamado, id_empresa]);

  const getEquipments = async (id_empresa = null) => {
    if (!id_empresa && !Boolean(chamado?.id)) {
      return;
    }

    let arr = [];

    if (id_empresa) {
      const empresa = empresas.find((empresa) => empresa.id === id_empresa);
      if (empresa) {
        arr = empresa.equipments
          .filter((equipment) => equipment.is_active !== 0)
          .map((equip) => ({
            value: equip.id,
            label:
              equip?.equipment +
              " | " +
              equip?.brand?.name +
              " | " +
              equip?.model?.name,
            servico: equip.service_type
          }));
      }
    } else if (Boolean(chamado?.id)) {
      arr = chamado?.company?.equipments
        .filter((equipment) => equipment.is_active !== 0)
        .map((equip) => ({
          value: equip.id,
          label:
            equip?.equipment +
            " | " +
            equip?.brand?.name +
            " | " +
            equip?.model?.name,
          servico: equip.service_type
        }));
    }

    setSelectEquipments(arr);
  };

  const handleChangeSelectEquipment = async (selectedOption) => {
    setEquipment_id(selectedOption.value);
    selectEquipments.forEach((e) => {
      if (e.value === selectedOption.value) {
        setEquipment(e.label);
        setServico(e.servico);
        return;
      }
    });
  };

  const handleChangeOption = (option) => {
    setStatus(option.value);
  };

  const handleChangeServico = (option) => {
    setServico(option.value);
  };

  const handleChangeOption2 = (option) => {
    setMaquinaParada(option.value);
  };

  const alterEmpresa = empresas;
  const alterTecnicos = tecnicos;
  const alterMarcas = marcas;
  const alterModelos = modelos;

  const options2 = [{ value: "", label: "" }];
  const options3 = [{ value: "", label: "" }];
  const options4 = [{ value: "", label: "" }];

  const options = [{ value: "", label: "" }];

  options.push(
    ...alterEmpresa.map((empresa) => ({
      value: empresa.company_id || empresa.id,
      label: empresa.name
    }))
  );

  const renderServiceNumber = (rowData) => {
    const year = rowData?.service_date?.split("-")[0];
    return year + "." + rowData?.service_number;
  };

  options2.push(
    ...alterTecnicos.map((tecnico) => ({
      value: tecnico.id,
      label: tecnico.name
    }))
  );

  options3.push(
    ...alterMarcas.map((marca) => ({
      value: marca.id,
      label: marca.name
    }))
  );

  options4.push(
    ...alterModelos.map((modelo) => ({
      value: modelo.id,
      label: modelo.name
    }))
  );

  const statusArr = ["Aberto", "Em andamento", "Fechado", "Cancelado"];
  const servicoArr = [
    "",
    "Contrato Locação",
    "Contrato Manutenção Preventiva + Corretiva",
    "Contrato Manutenção Preventiva",
    "Serviço à Faturar",
    "Frota",
    "Garantia",
    "Entrega Técnica"
  ];

  const getCurrentDate = (data) => {
    return data ? data.toISOString().slice(0, 10) : data;
  };

  const getCurrentTime = (date) => {
    return date ? date?.toLocaleTimeString("pt-BR").slice(0, 5) : date;
  };

  const handleApproveChamado = async () => {
    const costumer_created_time = getCurrentTime(horaDoChamadoCliente);
    const tech_warn_time = getCurrentTime(horaDoAvisoTecnico);
    const costumer_service_date = getCurrentDate(horaDeAtendimento);
    const costumer_service_time = getCurrentTime(horaDeAtendimento);

    const data = {
      status,
      comments: observacoes,
      costumer_created_time,
      tech_warn_time,
      tech_id: tecnico_id,
      report_number: relatorio,
      costumer_service_date,
      costumer_service_time,
      service_type: servico
    };
    onHide();

    try {
      setToast({
        severity: "success",
        detail: "Chamado aprovado com sucesso."
      });
      await api.post(`service_calls/approve/${chamado.id}`, data);
    } catch (e) {
      setToast({
        severity: "error",
        detail: "Ocorreu um erro ao aprovar chamado."
      });
    }

    if (reload) {
      await reload();
    }
    setLoading(false);
  };

  const handleEditChamado = async (data) => {
    onHide();

    try {
      await api.put(`service_calls/${chamado.id}`, data);
      setToast({ severity: "success", detail: "Chamado editado com sucesso." });
    } catch (e) {
      setToast({
        severity: "error",
        detail: "Ocorreu um erro ao editar chamado."
      });
    }

    reload();
    setLoading(false);
  };

  async function handleNewChamado() {
    if (!equipment_id || !descricaoDoProblema || !id_empresa) {
      setToast({
        severity: "warn",
        detail: "Preencha o equipamento, a empresa e a descrição do problema"
      });
      return;
    }

    setLoading(true);
    setChamado({});
    if (chamado.aprovar) {
      return await handleApproveChamado();
    }

    const machine_stopped = maquinaParada === "Sim" ? true : false;

    const costumer_created_time = getCurrentTime(horaDoChamadoCliente);
    const tech_warn_time = getCurrentTime(horaDoAvisoTecnico);
    const costumer_service_date = getCurrentDate(dataDoAtendimento);
    const costumer_service_time = getCurrentTime(horaDeAtendimento);
    const service_date = getCurrentDate(dataDoChamado);

    const data = {
      machine_stopped,
      company_id: id_empresa,
      equipment_id,
      error_description: descricaoDoProblema,
      comments: observacoes
    };

    let quality = null;

    if (
      status === "Fechado" &&
      service_date &&
      costumer_service_date &&
      costumer_created_time &&
      costumer_service_time
    ) {
      quality = maisque24(
        service_date,
        costumer_service_date,
        costumer_created_time,
        costumer_service_time
      );
      if (!quality) {
        quality = compararHora(costumer_created_time, costumer_service_time);
      }
      if (!quality) {
        quality = entre4e24(
          service_date,
          costumer_service_date,
          costumer_created_time,
          costumer_service_time
        );
      }
    }

    if (access_type === "Admin") {
      data.status = status;
      data.service_date = service_date;
      data.costumer_created_time = costumer_created_time;
      data.tech_warn_time = tech_warn_time;
      data.tech_id = tecnico_id;
      data.report_number = relatorio;
      data.costumer_service_date = costumer_service_date;
      data.costumer_service_time = costumer_service_time;
      data.service_type = servico;
      data.quality = quality;
      data.is_rework = false;
      data.rework_id = null;

      if (is_rework) {
        data.is_rework = is_rework;
        data.rework_id = rework_id;
      }

      if (status === "Cancelado") {
        if (!cancelJustification) {
          setToast({
            severity: "warn",
            detail: "Preencha a justificativa do cancelamento"
          });
          return;
        }
        data.cancel_justification = cancelJustification;
      }
    }

    if (chamado.edited) {
      return await handleEditChamado(data);
    }

    try {
      onHide();
      applyDefaultValues();
      await api.post("service_calls", data);

      setToast({ severity: "success", detail: "Chamado criado com sucesso." });

      await reload();
    } catch (err) {
      setToast({
        severity: "error",
        detail: "Ocorreu um erro ao enviar chamado."
      });
    }
    setLoading(false);
  }

  function getMaior(n1, n2) {
    if (n1 > n2) {
      return n1;
    } else {
      return n2;
    }
  }

  function getMenor(n1, n2) {
    if (n1 < n2) {
      return n1;
    } else {
      return n2;
    }
  }

  function compararHora(hora1, hora2) {
    hora1 = hora1.split(":");
    hora2 = hora2.split(":");

    if (hora2[0] - hora1[0] <= 4) {
      if (
        hora2[0] - hora1[0] === 4 &&
        getMaior(hora1, hora2) - getMenor(hora1, hora2) === 0
      ) {
        return "Ótimo";
      }
      if (hora2[0] - hora1[0] < 4) {
        return "Ótimo";
      }
    }
    return null;
  }

  function entre4e24(
    dataDoChamado,
    dataDoAtendimento,
    horaDoChamadoCliente,
    hora2
  ) {
    if (dataDoChamado && dataDoAtendimento && horaDoChamadoCliente && hora2) {
      horaDoChamadoCliente = horaDoChamadoCliente.split(":"); // 10:15
      hora2 = hora2.split(":"); // 16:25
      dataDoChamado = dataDoChamado.split("-"); //2020-07-24
      dataDoAtendimento = dataDoAtendimento.split("-"); //2020-07-25
      if (
        dataDoChamado[2] === 28 &&
        dataDoChamado[1] === 2 &&
        !getBissexto(dataDoChamado[0]) &&
        dataDoAtendimento[2] === 1
      ) {
        if (horaDoChamadoCliente[0] <= hora2[0]) {
          return "Bom";
        }
      } else if (dataDoChamado[2] === 31 && dataDoAtendimento === 1) {
        if (horaDoChamadoCliente[0] <= hora2[0]) {
          return "Bom";
        }
      } else if (dataDoChamado[2] === 30 && dataDoAtendimento === 1) {
        if (horaDoChamadoCliente[0] <= hora2[0]) {
          return "Bom";
        }
      } else if (dataDoChamado[2] === dataDoAtendimento[2]) {
        return "Bom";
      }
    }

    return null;
  }

  function maisque24(
    dataDoChamado,
    dataDoAtendimento,
    horaDoChamadoCliente,
    hora2
  ) {
    if (dataDoChamado && dataDoAtendimento && horaDoChamadoCliente && hora2) {
      horaDoChamadoCliente = horaDoChamadoCliente.split(":"); // 10:15
      hora2 = hora2.split(":"); // 16:25
      dataDoChamado = dataDoChamado.split("-"); //2020-07-24
      dataDoAtendimento = dataDoAtendimento.split("-"); //2020-07-25

      if (
        dataDoChamado[2] === 28 &&
        dataDoChamado[1] === 2 &&
        !getBissexto(dataDoChamado[0]) &&
        dataDoAtendimento[2] === 1
      ) {
        if (horaDoChamadoCliente[0] > hora2[0]) {
          return "Ruim";
        }
      } else if (dataDoChamado[2] === 31 && dataDoAtendimento === 1) {
        if (horaDoChamadoCliente[0] > hora2[0]) {
          return "Ruim";
        }
      } else if (dataDoChamado[2] === 30 && dataDoAtendimento === 1) {
        if (horaDoChamadoCliente[0] > hora2[0]) {
          return "Ruim";
        }
      } else if (dataDoAtendimento[2] > dataDoChamado[2]) {
        return "Ruim";
      }
    }
    return null;
  }

  function getBissexto(ano) {
    return ano % 4 === 0 && (ano % 100 !== 0 || ano % 400 === 0);
  }

  const constructJavascriptDate = (date) => {
    if (date) {
      const splited = date.split("-");
      const day = splited[2];
      const month = splited[1] - 1;
      const year = splited[0];

      return new Date(year, month, day);
    }
  };

  const templateSelectPrime = (option) => {
    return <div style={{ maxWidth: "500px" }}>{option.label}</div>;
  };

  const [lazyItems, setLazyItems] = useState([]);
  const [lazyLoading, setLazyLoading] = useState(false);

  const createChamadoAdmin = () => {
    return (
      <Fade in={true}>
        <div className="container">
          <div className={"row"}>
            <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
              <form
                className={
                  "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
                }
                onSubmit={handleNewChamado}
              >
                <div className={"row pb-1"}>
                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="MaquinaParada">Maquina está parada?:</label>
                    <Dropdown
                      disabled={chamado.aprovar}
                      value={maquinaParada}
                      options={["Sim", "Nao"]}
                      onChange={handleChangeOption2}
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="Status">Status:</label>
                    <Dropdown
                      disabled={disableStatus || chamado.aprovar}
                      value={status}
                      options={statusArr}
                      onChange={handleChangeOption}
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="data">Data do chamado: </label>
                    <Calendar
                      disabled={chamado.aprovar || chamado.created_by_costumer}
                      name={"data"}
                      value={dataDoChamado}
                      onFocus={() => setDataDoChamado(new Date())}
                      onChange={(e) => setDataDoChamado(e.value)}
                      mask="99/99/9999"
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="horaChamadoCliente">
                      Hora do Chamado - Cliente
                    </label>

                    <Calendar
                      disabled={chamado.aprovar || chamado.created_by_costumer}
                      name={"horaChamadoCliente"}
                      onFocus={() => setHoraDoChamadoCliente(new Date())}
                      value={horaDoChamadoCliente}
                      onChange={(e) => setHoraDoChamadoCliente(e.value)}
                      timeOnly
                    />
                  </div>

                  <div className={"col-md-8 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">Empresa: </label>
                    <Dropdown
                      disabled={chamado.aprovar || chamado.created_by_costumer}
                      value={nomeEmpresa}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={nomeEmpresa}
                      options={options}
                      onChange={handleChangeSelect}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione a empresa"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>
                </div>
                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">Equipamento: </label>
                    <Dropdown
                      value={equipment}
                      itemTemplate={templateSelectPrime}
                      disabled={disableEquipment || chamado.aprovar}
                      valueTemplate={equipment}
                      options={selectEquipments}
                      onChange={handleChangeSelectEquipment}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione um equipamento"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>
                </div>
                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Descrição do problema">
                      Descrição do problema:{" "}
                    </label>
                    <InputTextarea
                      style={{ maxWidth: "100%", height: "50px" }}
                      value={descricaoDoProblema}
                      onChange={(e) => setDescricaoDoProblema(e.target.value)}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="Tecnico">Técnico: </label>
                    <Dropdown
                      value={tecnico}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={tecnico}
                      options={options2}
                      onChange={handleChangeSelectTecnico}
                      filter
                      placeholder="Selecione o técnico"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="horaAvisoTecnico">
                      {" "}
                      Hora do aviso ao técnico{" "}
                    </label>
                    <Calendar
                      name={"horaDoAvisoTecnico"}
                      onFocus={() => setHoraDoAvisoTecnico(new Date())}
                      value={horaDoAvisoTecnico}
                      onChange={(e) => setHoraDoAvisoTecnico(e.value)}
                      timeOnly
                    />
                  </div>

                  <div className={"col-md-4 pb-3"}>
                    <label htmlFor="relatorio">Relatório </label>
                    <InputText
                      name="relatorio"
                      value={relatorio}
                      onChange={(e) => setRelatorio(e.target.value)}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="dataAtendimento">
                      Data do atendimento:{" "}
                    </label>
                    <Calendar
                      name={"dataAtendimento"}
                      onFocus={() => setDataDoAtendimento(new Date())}
                      value={dataDoAtendimento}
                      onChange={(e) => setDataDoAtendimento(e.value)}
                      mask="99/99/9999"
                    />
                  </div>

                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="Hora atendimento">
                      Hora de atendimento:{" "}
                    </label>
                    <Calendar
                      name={"Hora atendimento"}
                      onFocus={() => setHoraDeAtendimento(new Date())}
                      value={horaDeAtendimento}
                      onChange={(e) => setHoraDeAtendimento(e.value)}
                      timeOnly
                    />
                  </div>

                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="Servico">Serviço:</label>

                    <Dropdown
                      value={servico}
                      options={servicoArr}
                      onChange={handleChangeServico}
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-12 d-flex flex-column"}>
                    <label htmlFor="Observações">Observações</label>
                    <InputTextarea
                      style={{ maxWidth: "100%", height: "50px" }}
                      value={observacoes}
                      onChange={(e) => setObservacoes(e.target.value)}
                    />
                  </div>
                </div>
                {status === "Cancelado" && (
                  <div className={"row pb-1 pt-3"}>
                    <div className={"col-md-12 d-flex flex-column"}>
                      <label htmlFor="justification">
                        Justificativa do cancelamento
                      </label>
                      <InputTextarea
                        style={{ maxWidth: "100%", height: "50px" }}
                        value={cancelJustification}
                        onChange={(e) => setCancelJustification(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                <div className={"row pb-1 pt-3"}>
                  <div
                    className={
                      "col-md-3 d-flex flex-column align-items-center justify-content-center"
                    }
                  >
                    <div className="form-check d-flex flex-row align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={is_rework}
                        id="flexCheckDefault"
                        onChange={() => setIsRework(!is_rework)}
                      />
                      <label
                        className="form-check-label ms-2"
                        htmlFor="flexCheckDefault"
                      >
                        Retrabalho
                      </label>
                    </div>
                  </div>
                  {is_rework && (
                    <div className={"col-md-9 d-flex flex-column"}>
                      <label>Chamado de retrabalho</label>
                      <Dropdown
                        value={rework}
                        itemTemplate={templateSelectPrime}
                        valueTemplate={rework}
                        options={chamadosRework}
                        onChange={handleChangeSelectRework}
                        placeholder="Selecione o chamado de retrabalho"
                        style={{ height: "50px", alignItems: "center" }}
                        filter
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
    );
  };

  const createChamadoCostumer = () => {
    return (
      <Fade in={true}>
        <div className="container">
          <div className={"row"}>
            <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
              <form
                className={
                  "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
                }
                onSubmit={handleNewChamado}
              >
                <div className={"row pb-1"}>
                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="MaquinaParada">Maquina está parada?:</label>
                    <Dropdown
                      value={maquinaParada}
                      options={["Sim", "Nao"]}
                      onChange={handleChangeOption2}
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">Empresa: </label>
                    <Dropdown
                      value={nomeEmpresa}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={nomeEmpresa}
                      options={options}
                      onChange={handleChangeSelect}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione a empresa"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-4 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">Equipamento: </label>
                    <Dropdown
                      disabled={disableEquipment}
                      value={equipment}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={equipment}
                      options={selectEquipments}
                      onChange={handleChangeSelectEquipment}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione um equipamento"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Descrição do problema">
                      Descrição do problema:{" "}
                    </label>
                    <InputTextarea
                      style={{ maxWidth: "100%", height: "50px" }}
                      value={descricaoDoProblema}
                      onChange={(e) => setDescricaoDoProblema(e.target.value)}
                    />
                  </div>
                </div>

                <div className={"row pb-1"}>
                  <div className={"col-md-12 d-flex flex-column"}>
                    <label htmlFor="Observações">Observações</label>
                    <InputTextarea
                      style={{ maxWidth: "100%", height: "50px" }}
                      value={observacoes}
                      onChange={(e) => setObservacoes(e.target.value)}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
    );
  };

  const constructCreateChamado = () => {
    if (access_type === "Admin") {
      return createChamadoAdmin();
    } else {
      return createChamadoCostumer();
    }
  };

  const saveProduct = async () => {
    await handleNewChamado();
  };

  const footer = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => {
          onHide();
          applyDefaultValues();
        }}
      />
      <Button
        label={chamado.aprovar ? "Aprovar" : "Salvar"}
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProduct}
      />
    </React.Fragment>
  );

  return (
    <>
      <Dialog
        visible={visible}
        style={{ marginTop: 60, width: "80rem" }}
        header={header}
        modal
        className="p-fluid"
        footer={footer}
        onHide={onHide}
      >
        <div className={"container pt-2"}>{constructCreateChamado()}</div>
      </Dialog>
    </>
  );
}
