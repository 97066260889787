import React, { useEffect, useRef, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import RowsDropdown from "../../components/RowsDropdown";
import DateRange from "../../components/ToolbarComponents/DateRange";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";
import "./style.css";
import DeleteDialog from "../../components/Dialogs/DeleteDialog";
import ChecklistDetails from "../../components/ChecklistDetails";
import ChecklistForm from "../../components/ChecklistForm";

const Checklists = () => {
  const { access_type, email } = useAuth();
  const [toastOptions, setToastOptions] = useState({});
  const [loading, setLoading] = useState(false);

  const [beginDate, setBeginDate] = useState(
    new Date(new Date().getFullYear(), 0, 1).toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31).toISOString().split("T")[0]
  );
  const [inputBeginDate, setInputBeginDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [inputEndDate, setInputEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const [rows, setRows] = useState(10);

  const toast = useRef(null);
  const dt = useRef(null);
  const [checklists, setChecklists] = useState([]);
  const [counter, setCounter] = useState(0);
  const [search, setSearch] = useState("");
  const [currentChecklist, setCurrentChecklist] = useState(null);

  const [page, setPage] = useState(1);

  const [sortOrder, setSortOrder] = useState(-1);
  const [sortField, setSortField] = useState("created_at");
  const [checklistDetailModal, setChecklistDetailModal] = useState(false);
  const [deleteChecklistModal, setDeleteChecklistModal] = useState(false);
  const [checklistFormModal, setChecklistFormModal] = useState(false);

  const ordenar = (sortField, sortOrder) => {
    setSortOrder(sortOrder);
    setSortField(sortField);
  };

  useEffect(() => {
    getChecklists();
  }, [
    page,
    search,
    beginDate,
    endDate,
    sortOrder,
    access_type,
    rows,
    sortField
  ]);

  const getChecklists = async () => {
    setLoading(true);
    await api
      .get(`/checklist`, {
        params: {
          page,
          limit: rows,
          search,
          beginDate,
          endDate,
          sortField,
          sortOrder
        }
      })
      .then((response) => {
        setChecklists(response.data.data);
        setCounter(response.data.total);
        setLoading(false);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Erro ao carregar checklists",
          life: 3000
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(toastOptions).length !== 0) {
      toast.current.show({
        severity: toastOptions.severity,
        summary: "",
        detail: toastOptions.detail,
        life: 5000
      });
    }
  }, [toastOptions]);

  const exportExcel = async () => {
    toast.current.show({
      severity: "info",
      summary: "",
      detail: "Gerando excel, aguarde...",
      life: 5000
    });
    setLoading(true);

    api
      .get(
        `/checklist/export/excel?beginDate=${beginDate}&endDate=${endDate}&search=${search}`,
        {
          responseType: "blob"
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const startDate =
          beginDate ||
          new Date(new Date().getFullYear(), 0, 1).toLocaleDateString();
        const finalDate =
          endDate ||
          new Date(new Date().getFullYear(), 11, 31).toLocaleDateString();
        link.setAttribute(
          "download",
          `checklists-${startDate}-${finalDate}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail:
            "Ocorreu um erro ao gerar excel, contate um administrador: " +
            error.message,
          life: 5000
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exportar"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportExcel}
        />
      </React.Fragment>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Novo"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            setChecklistFormModal(false);
            setCurrentChecklist(null);
            requestAnimationFrame(() => {
              setChecklistFormModal(true);
            });
          }}
        />
      </React.Fragment>
    );
  };

  function dataConstruct(data) {
    if (!data) {
      return "";
    }

    let splitT = data.split("T");
    let split = splitT[0].split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
  }

  const showChecklistDetails = (rowData) => {
    setCurrentChecklist(null);
    setChecklistDetailModal(true);
    setLoading(true);
    api
      .get(`/checklist/${rowData.id}`)
      .then((response) => {
        setCurrentChecklist(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Erro ao carregar detalhes do checklist",
          life: 3000
        });
        setLoading(false);
        setChecklistDetailModal(false);
      });
  };

  const handleDeleteChecklist = async () => {
    setLoading(true);
    try {
      await api.delete(`/checklist/${currentChecklist.id}`);
      toast.current.show({
        severity: "success",
        summary: "Sucesso",
        detail: "Checklist excluído com sucesso",
        life: 3000
      });
      getChecklists();
      setDeleteChecklistModal(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao excluir checklist",
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const showChecklistForm = (rowData) => {
    setCurrentChecklist(null);
    setChecklistFormModal(true);
    setLoading(true);

    api
      .get(`/checklist/${rowData.id}`)
      .then((response) => {
        setCurrentChecklist(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Erro ao carregar dados do checklist",
          life: 3000
        });
        setLoading(false);
        setChecklistFormModal(false);
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          tooltip="Visualizar"
          tooltipOptions={{ position: "top" }}
          style={{
            backgroundColor: "#0A073B",
            outline: 0,
            border: "none"
          }}
          className="p-button-rounded p-button text-white p-mr-2 me-2"
          onClick={() => showChecklistDetails(rowData)}
        />
        <Button
          icon="pi pi-pencil"
          tooltip="Editar"
          tooltipOptions={{ position: "top" }}
          style={{
            backgroundColor: "#f29a37",
            outline: 0,
            border: "none",
            marginLeft: 6
          }}
          className="p-button-rounded p-button text-white p-mr-2 me-2"
          onClick={() => showChecklistForm(rowData)}
        />
        <Button
          icon="pi pi-file-pdf"
          tooltip="PDF"
          tooltipOptions={{ position: "top" }}
          style={{
            backgroundColor: "#7f0b0b",
            outline: 0,
            border: "none",
            marginLeft: 6
          }}
          className="p-button-rounded p-button text-white"
          onClick={() =>
            window.open(`/pdf/checklist;${rowData.id}`, "_blank").focus()
          }
        />
        {access_type === "Admin" && (
          <Button
            tooltip="Excluir"
            tooltipOptions={{ position: "top" }}
            icon="pi pi-trash"
            style={{
              outline: 0,
              border: "none",
              marginLeft: 6
            }}
            className="p-button-rounded p-button-danger text-white p-mr-2 me-2"
            onClick={() => {
              setCurrentChecklist(rowData);
              setDeleteChecklistModal(true);
            }}
          />
        )}
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header checklists-table-header">
      <div className="pt-2">
        <h5 className="p-m-0">Checklists</h5>
        <span className="p-input-icon-left d-flex">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => {
              setSearch(e.target.value);
              setPage(1);
            }}
            placeholder="Busca..."
          />
        </span>
      </div>
      <DateRange
        inputBeginDate={inputBeginDate}
        setInputBeginDate={setInputBeginDate}
        beginDate={beginDate}
        setBeginDate={setBeginDate}
        setInputEndDate={setInputEndDate}
        setEndDate={setEndDate}
        inputEndDate={inputEndDate}
        endDate={endDate}
      />
    </div>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div className="datatable-responsive-demo">
        <Toast style={{ zIndex: 1101 }} ref={toast} />
        <div className="card">
          <Toolbar className="p-mb-4" right={rightToolbarTemplate} />
          {access_type === "Admin" && (
            <Toolbar className="p-mb-4" left={leftToolbarTemplate} />
          )}

          <DataTable
            ref={dt}
            value={checklists}
            dataKey="id"
            rows={rows}
            header={header}
            sortOrder={sortOrder}
            sortField={sortField}
            onSort={({ sortField, sortOrder }) => ordenar(sortField, sortOrder)}
            className={"p-datatable-responsive-demo"}
            loading={loading}
          >
            <Column headerStyle={{ width: "3rem" }} />
            <Column
              headerStyle={{ width: "10rem" }}
              field="created_at"
              sortable={true}
              header="Data"
              body={(rowData) => dataConstruct(rowData.created_at)}
            />
            <Column field="company.name" header="Empresa" />
            <Column
              field="equipment.equipment"
              header="Equipamento"
              body={(rowData) => rowData.equipment?.equipment || "N/A"}
            />
            <Column
              field="created_by.name"
              header="Criado por"
              body={(rowData) => rowData.created_by?.name || "N/A"}
            />
            <Column header="Ações" body={actionBodyTemplate} />
          </DataTable>
          <div
            className={"d-flex justify-content-center align-items-center p-3"}
          >
            <div>
              <Pagination
                className="pagination"
                color={"primary"}
                boundaryCount={1}
                count={Math.ceil(counter / rows)}
                onChange={handleChangePage}
              />
            </div>
            <RowsDropdown rows={rows} setRows={setRows} />
          </div>
        </div>
      </div>

      <ChecklistDetails
        visible={checklistDetailModal}
        onHide={() => {
          setChecklistDetailModal(false);
          setCurrentChecklist(null);
        }}
        currentChecklist={currentChecklist}
        loading={loading}
      />

      <DeleteDialog
        visible={deleteChecklistModal}
        onHide={() => setDeleteChecklistModal(false)}
        onConfirm={handleDeleteChecklist}
        loading={loading}
        message="Deseja realmente excluir este checklist? Esta ação não pode ser desfeita."
        header="Confirmar Exclusão"
        confirmLabel="Excluir"
        cancelLabel="Cancelar"
      />

      <ChecklistForm
        key={currentChecklist ? currentChecklist.id : "new"}
        visible={checklistFormModal}
        onHide={() => {
          setChecklistFormModal(false);
          setCurrentChecklist(null);
        }}
        currentChecklist={currentChecklist}
        loading={loading}
        toast={toast}
        onSave={() => {
          setChecklistFormModal(false);
          setCurrentChecklist(null);
          getChecklists();
        }}
      />
    </>
  );
};

export default Checklists;
