import Pagination from "@material-ui/lab/Pagination";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { useQuery } from "react-query";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import Push from "push.js";
import React, { useEffect, useRef, useState } from "react";
import { useLayoutEffect } from "react";
import { useHistory } from "react-router";
import ConfirmModal from "../../../components/ConfirmModal";
import CreateChamadosForm from "../../../components/CreateChamadosForm";
import MultipleCorrectivesModal from "../../../components/MultipleCorrectivesModal/MultipleCorrectivesModal";
import RowsDropdown from "../../../components/RowsDropdown";
import ServiceCallFilters from "../../../components/ServiceCallFilters/ServiceCallFilters";
import DateRange from "../../../components/ToolbarComponents/DateRange";
import { useAuth } from "../../../contexts/AuthContext";
import api from "../../../services/api";
import masks from "../../../utils/masks";
import "./styles.css";

const EquipmentTable = ({ pat, equipmentId }) => {
  const { access_type } = useAuth();
  const [rows, setRows] = useState(10);

  const [productDialog, setProductDialog] = useState(false);
  const [toastOptions, setToastOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingStatusNumber, setLoadingStatusNumber] = useState(true);
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [inputBeginDate, setInputBeginDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [inputEndDate, setInputEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    api.get("companies/all").then((response) => {
      setEmpresas(response.data);
    });
  }, [access_type]);

  const [tecnicos, setTecnicos] = useState([]);

  useEffect(() => {
    if (access_type === "Admin") {
      api.get("/users/getTechs").then((response) => {
        setTecnicos(response.data);
      });
    }
  }, [access_type]);

  const [singleChamado, setSingleChamado] = useState({});
  const [sortField, setSortField] = useState("service_date");
  const [sortOrder, setSortOrder] = useState(-1);
  const [singleChamadoModal, setSingleChamadoModal] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const [ordenado, setOrdenado] = useState("");
  const [status, setStatus] = useState("");
  const [multiEmpresas, setMultiEmpresas] = useState([]);
  const [multiTechs, setMultiTechs] = useState([]);
  const [multiEquips, setMultiEquips] = useState([]);
  const [maquinaParada, setMaquinaParada] = useState("");

  const { isLoading, error, data, refetch } = useQuery(
    [
      "get-chamados",
      {
        page,
        search,
        ordenado,
        sortOrder,
        beginDate,
        endDate,
        status,
        rows,
        multiTechs,
        multiEmpresas,
        maquinaParada,
        pat,
        equipmentId
      }
    ],
    {
      queryFn: () => getChamados(),

      refetchOnWindowFocus: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: 60000
    }
  );

  const chamadosAll = data?.data?.data || [];

  const getChamados = async () => {
    setLoading(true);

    const response = await api.get(
      `service_calls/getServiceCallsByEquipmentId/${equipmentId}?page=${page}&search=${search}&orderBy=${ordenado}&beginDate=${beginDate}&endDate=${endDate}&status=${status}&rows=${rows}`
    );

    setLoading(false);

    return response;
  };

  useEffect(() => {
    if (Object.keys(toastOptions).length !== 0) {
      toast.current.show({
        severity: toastOptions.severity,
        summary: "",
        detail: toastOptions.detail,
        life: 5000
      });
    }
  }, [toastOptions]);

  const openNew = (rowData = null) => {
    setProductDialog(true);
    document.body.style.position = "fixed";
    setSingleChamado({});
    if (rowData) {
      rowData.edited = true;
      setSingleChamado(rowData);
    }
  };

  const hideDialog = () => {
    setSingleChamado({});
    setProductDialog(false);
    document.body.style.position = null;
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Novo"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => openNew(null)}
        />
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData.status === "Aberto") {
      return (
        <Badge
          style={{ minWidth: 100, backgroundColor: "#9E3131", color: "white" }}
          value={rowData.status}
        />
      );
    } else if (rowData.status === "Em andamento") {
      return (
        <Badge
          style={{ minWidth: 100, color: "white" }}
          severity={"warning"}
          value={rowData.status}
        />
      );
    } else if (rowData.status === "Fechado") {
      return (
        <Badge
          style={{ minWidth: 100, backgroundColor: "#003B17", color: "white" }}
          value={rowData.status}
        />
      );
    } else if (
      rowData.status === "Pendente" ||
      rowData.status === "Pendente peça"
    ) {
      return (
        <Badge
          style={{
            minWidth: 100,
            backgroundColor: "#FF8B00",
            color: "white",
            textWrap: "nowrap"
          }}
          value={
            rowData.status === "Pendente"
              ? "Pendente corretiva"
              : "Pendente peça"
          }
        />
      );
    }
    return (
      <Badge
        style={{ minWidth: 100, backgroundColor: "gray", color: "white" }}
        value={rowData.status}
      />
    );
  };

  const techBodyTemplate = (rowData) => {
    if (!rowData?.tech?.name) {
      return <span style={{ color: "red" }}>{"Sem técnico"}</span>;
    }

    return rowData.tech.name;
  };

  function dataConstruct(data) {
    if (!data) {
      return "";
    }

    let splitT = data.split("T");
    let split = splitT[0].split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
  }

  const showSingleChamadoModal = (rowData) => {
    setSingleChamado(rowData);
    setSingleChamadoModal(true);
    document.body.style.position = "fixed";
  };

  const downloadReport = async (corrective) => {
    return window.open(`/pdf/corrective;${corrective.id}`, "_blank").focus();
  };

  const [acceptModal, setAcceptModal] = useState(false);

  const openAcceptModal = (rowData) => {
    setSingleChamado(rowData);
    setAcceptModal(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        {access_type === "Admin" && (
          <Button
            tooltip="Editar"
            tooltipOptions={{ position: "top" }}
            icon="pi pi-pencil"
            className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
            onClick={() => openNew(rowData)}
          />
        )}
        <Button
          icon="pi pi-info"
          tooltip="Informações"
          tooltipOptions={{ position: "top" }}
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          className="p-button-rounded p-button text-white"
          onClick={() => {
            rowData.show = true;
            showSingleChamadoModal(rowData);
          }}
        />
        {rowData.pending_approval === 1 && (
          <Button
            icon="pi pi-times-circle"
            tooltip="Cancelar"
            tooltipOptions={{ position: "top" }}
            style={{ outline: 0, border: "none", marginLeft: 6 }}
            className="p-button-rounded p-button-danger text-white p-mr-2 me-2"
            onClick={() => openAcceptModal(rowData)}
          />
        )}
        {rowData.corrective_count > 0 && (
          <span
            onClick={
              rowData.corrective_count === 1
                ? () => downloadReport(rowData.corrective[0])
                : () => showMultipleCorrectiveModalHandler(rowData)
            }
            className="corrective-download-wrapper"
          >
            <Button
              tooltip="Download corretiva"
              tooltipOptions={{ position: "top" }}
              icon="pi pi-file-pdf"
              style={{
                backgroundColor: "#7f0b0b",
                outline: 0,
                border: "none",
                marginLeft: 6
              }}
              className="p-button-rounded p-button text-white corrective-download-button"
            />
            <span className="corrective-download-count">
              {rowData.corrective_count}
            </span>
          </span>
        )}
      </>
    );
  };

  async function handleShareChamados(chamado) {
    if (!chamado) {
      toast.current.show({
        severity: "error",
        summary: "",
        detail: "Não foi possivel compartilhar esse chamado",
        life: 5000
      });
      return;
    }
    setLoading(true);
    const year = chamado?.service_date?.split("-")[0];
    const number = year + "." + chamado.service_number;

    let message = `*GRUPO MOV - INFORMAÇÕES*%0A%0A*Chamado Nº.:* ${number}%0A*Data:* ${
      chamado.service_date ? dataConstruct(chamado.service_date) : ""
    }%0A*Cliente:* ${
      chamado?.company?.name ? chamado?.company?.name : ""
    }%0A*Marca/Modelo:* ${
      chamado?.equipment?.brand?.name + " / " + chamado?.equipment?.model?.name
    }%0A*SN / PAT:* ${
      chamado?.equipment?.serial_number + " / " + chamado?.equipment?.pat
    }%0A*Tipo de serviço:* ${chamado?.service_type ? chamado.service_type : ""}
    %0A*Descrição do problema:* ${chamado.error_description}`;

    for (let i = 0; i < 5; i++) {
      message = message.replace("undefined", "");
      message = message.replace("&", "e");
    }
    let addressUrl = chamado?.company?.address_link;

    if (!addressUrl) {
      addressUrl =
        process.env.REACT_APP_LARAVEL_APP +
        "api/address?cnpj=" +
        chamado.company.cnpj;
    }

    message += "%0A*Endereço:* " + addressUrl;

    const url = "https://web.whatsapp.com/send?text=" + message;

    setLoading(false);
    window.open(url, "Compartilhamento");
  }

  const ordenar = (sortField, sortOrder) => {
    setSortField(sortField);
    setSortOrder(sortOrder);
    setOrdenado(sortField);
  };

  const menu = useRef(null);
  const searchInput = useRef(null);

  const handleSearch = () => {
    const search = searchInput?.current?.value ?? "";
    setSearch(search);
    setPage(1);
  };

  const header = (
    <div className="table-header chamados-table-header">
      <div className="pt-2">
        <h5 className="p-m-0">Todos os chamados</h5>
        <span className="p-input-icon-left d-flex">
          <i className="pi pi-search" />
          <InputText
            type="search"
            ref={searchInput}
            placeholder="Busca..."
            onInput={(e) => {
              if (!e.target.value) {
                setSearch("");
              }
            }}
          />
          <Button
            style={{ color: "white", marginLeft: 8 }}
            icon="pi pi-search"
            onClick={() => handleSearch()}
          />
        </span>
      </div>
      <DateRange
        inputBeginDate={inputBeginDate}
        setInputBeginDate={setInputBeginDate}
        beginDate={beginDate}
        setBeginDate={setBeginDate}
        setInputEndDate={setInputEndDate}
        setEndDate={setEndDate}
        inputEndDate={inputEndDate}
        endDate={endDate}
      />
    </div>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const renderServiceNumber = (rowData) => {
    const year = rowData?.service_date?.split("-")[0];
    return year + "." + rowData.service_number;
  };

  const cancelChamado = async () => {
    await api
      .post(`service_calls/cancel/${singleChamado.id}`)
      .then((response) => {
        toast.current.show({
          severity: "success",
          summary: "",
          detail: response.data.response,
          life: 5000
        });
      });

    setSingleChamado({});
    setAcceptModal(false);
    refetch();
  };

  const selectOnlyOneStatus = (_status) => {
    setPage(1);

    if (status === _status) {
      setStatus("");
      return;
    }
    setStatus(_status);
  };

  const [showFilter, setShowFilter] = useState(false);
  const onHideFilter = () => setShowFilter(false);

  const [showMultipleCorrectiveModal, setShowMultipleCorrectiveModal] =
    useState(false);

  const onHideMultipleCorrectiveModal = () => {
    setShowMultipleCorrectiveModal(false);
    setSingleChamado({});
  };

  const showMultipleCorrectiveModalHandler = (rowData) => {
    setSingleChamado(rowData);
    setShowMultipleCorrectiveModal(true);
  };

  return (
    <>
      <Toast style={{ zIndex: 1101 }} ref={toast} />

      <div className="datatable-responsive-demo">
        <Toast style={{ zIndex: 1101 }} ref={toast} />

        <div className="card">
          <Toolbar className="p-mb-4" left={leftToolbarTemplate} />

          <DataTable
            ref={dt}
            value={chamadosAll}
            dataKey="id"
            rows={rows}
            header={header}
            sortOrder={sortOrder}
            sortField={ordenado}
            onSort={({ sortField, sortOrder }) => ordenar(sortField, sortOrder)}
            className={"p-datatable-responsive-demo"}
            loading={loading || isLoading}
          >
            <Column headerStyle={{ width: "3rem" }} />
            <Column
              headerStyle={{ width: "10rem" }}
              field="service_number"
              header="Nº Cham."
              sortable={true}
              body={(rowData) => renderServiceNumber(rowData)}
            />
            <Column field="company.name" header="Empresa" sortable={true} />
            <Column field="error_description" header="Descrição do problema" />
            <Column
              field={"machine_stopped"}
              header="Máquina parada"
              body={(rowData) =>
                rowData.machine_stopped ? <span>Sim</span> : "Não"
              }
            />
            <Column
              field="service_date"
              header="Data"
              sortable={true}
              body={(rowData) => dataConstruct(rowData.service_date)}
            />
            <Column
              field="tech.name"
              header="Técnico"
              body={techBodyTemplate}
              sortable={true}
            />
            <Column
              field="status"
              header="Status"
              sortable={true}
              body={statusBodyTemplate}
            />
            <Column header="Ações" body={actionBodyTemplate} />
          </DataTable>
          {chamadosAll.length === 0 && !loading && !isLoading && (
            <h1 style={{ textAlign: "center", marginTop: 20 }}>
              Nenhum chamado encontrado
            </h1>
          )}

          <div
            className={"d-flex justify-content-center align-items-center p-3"}
          >
            <div>
              <Pagination
                className="pagination"
                color={"primary"}
                boundaryCount={1}
                count={Math.ceil(data?.data?.total / rows)}
                onChange={handleChangePage}
              />
            </div>
            <RowsDropdown rows={rows} setRows={setRows} pl />
          </div>
        </div>

        <CreateChamadosForm
          chamados={chamadosAll}
          setLoading={setLoading}
          reload={refetch}
          setToast={setToastOptions}
          header={"Novo chamado"}
          chamado={singleChamado}
          setChamado={setSingleChamado}
          visible={productDialog}
          onHide={hideDialog}
          empresas={empresas}
          tecnicos={tecnicos}
        />

        <ConfirmModal
          type={"reject"}
          visible={acceptModal}
          onHide={() => setAcceptModal(false)}
          refused={() => setAcceptModal(false)}
          accepted={cancelChamado}
          label={"Deseja cancelar esse chamado?"}
          extra={
            "Se cancelar esse chamado, terá de abrir outro para resolver seu problema."
          }
        />
        <ServiceCallFilters
          setMultiEmpresas={setMultiEmpresas}
          setMultiTechs={setMultiTechs}
          empresas={empresas}
          tecnicos={tecnicos}
          isVisible={showFilter}
          onHide={onHideFilter}
          maquinaParada={maquinaParada}
          setMaquinaParada={setMaquinaParada}
          setMultiEquips={setMultiEquips}
        />
        <MultipleCorrectivesModal
          isVisible={showMultipleCorrectiveModal}
          onHide={onHideMultipleCorrectiveModal}
          setLoading={setLoading}
          loading={loading}
          chamado={singleChamado}
        />
        <Dialog
          header={"Chamado"}
          visible={singleChamadoModal}
          style={{ width: "50vw" }}
          onHide={() => {
            setSingleChamadoModal(false);
            document.body.style.position = null;
          }}
          footer={
            singleChamado.equipment &&
            singleChamado.pending_approval === 0 && (
              <Button
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 18,
                  outline: "none",
                  border: "none",
                  backgroundColor: "#095009"
                }}
                icon="pi pi-comments"
                label="Compartilhar"
                onClick={() => handleShareChamados(singleChamado)}
              />
            )
          }
          baseZIndex={1000}
        >
          <div className="container-fluid">
            <div className="row flex-row row-reverse">
              <div className="col-md-6">
                <strong>Chamado: </strong>
                <p>{renderServiceNumber(singleChamado)}</p>

                <strong>Maquina Parada: </strong>
                <p>{singleChamado.machine_stopped === 0 ? "Não" : "Sim"}</p>

                <strong>Nome empresa: </strong>
                <p>{singleChamado.company?.name}</p>

                <strong>Data do chamado: </strong>
                <p>{dataConstruct(singleChamado.service_date)} </p>

                <strong>Status: </strong>
                <p>{singleChamado.status}</p>

                <strong>Qualidade: </strong>
                <p>{singleChamado.quality}</p>

                <strong>Nome de contato: </strong>
                <p>{singleChamado.company?.contact_name}</p>

                <strong>Telefone: </strong>
                <p>{masks.phone(singleChamado.company?.phone)}</p>

                <strong>Aberto Por: </strong>
                <p>{singleChamado.created_by?.name}</p>

                <strong>Aceite técnico: </strong>
                <p>{singleChamado?.accepted_by_tech === 1 ? "Sim" : "Não"}</p>

                <strong>É retrabalho: </strong>
                <p>{singleChamado?.is_rework === 1 ? "Sim" : "Não"}</p>
              </div>

              <div className="col-md-6">
                <strong>Descricao do Problema: </strong>
                <p>{singleChamado.error_description}</p>

                <strong>Hora do aviso tecnico </strong>
                <p>{singleChamado.tech_warn_time}</p>

                <strong>Hora do chamado - Cliente </strong>
                <p>{singleChamado.costumer_created_time}</p>

                <strong>Tecnico: </strong>
                <p>{singleChamado.tech?.name}</p>

                <strong>Relatorio: </strong>
                <p>{singleChamado.report_number}</p>

                <strong>Data do Atendimento </strong>
                <p>{dataConstruct(singleChamado.costumer_service_date)}</p>

                <strong>Hora de Atendimento </strong>
                <p>{singleChamado.costumer_service_time}</p>

                <strong>Observações: </strong>
                <p>{singleChamado.comments}</p>

                <strong>Serviço: </strong>
                <p>{singleChamado.service_type}</p>

                <strong>Equipamento: </strong>
                <p>
                  {singleChamado.equipment &&
                    singleChamado.equipment?.equipment +
                      " | " +
                      singleChamado.equipment?.brand?.name +
                      " | " +
                      singleChamado.equipment?.model?.name}
                </p>
                {singleChamado?.status === "Cancelado" && (
                  <>
                    <strong>Justificativa do cancelamento: </strong>
                    <p>{singleChamado.cancel_justification}</p>
                  </>
                )}

                {singleChamado?.is_rework ? (
                  <>
                    <strong>Retrabalho: </strong>
                    <p>
                      {renderServiceNumber(singleChamado.rework) +
                        " | " +
                        singleChamado?.rework?.company?.name}
                    </p>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default EquipmentTable;
