import QuantityCard from "../../components/QuantityCard-v2";
import "./dashboard.css";
import DateRange from "../../components/ToolbarComponents/DateRange";
import { useRef, useState, useEffect, useMemo } from "react";
import { Chart } from "primereact/chart";
import { useQuery } from "react-query";
import api from "../../services/api";
import { secondsToHoursMinutes } from "../../utils/masks";
import DateRangeDashboard from "../../components/ToolbarComponents/DateRangeDashboard";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { getTimeAgo } from "../../utils/masks";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ReportsDrawer from "../../components/ReportsDrawer";

export default function Dashboard() {
  const currentDate = new Date();
  const todayStart = new Date(currentDate);
  todayStart.setHours(-3, 0, 0, 0);
  const todayEnd = new Date(currentDate);
  todayEnd.setHours(20, 59, 59, 999);

  const [beginDate, setBeginDate] = useState(todayStart);
  const [endDate, setEndDate] = useState(todayEnd);
  const [tecnicos, setTecnicos] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState("all");
  const [inputBeginDate, setInputBeginDate] = useState(todayStart);
  const [inputEndDate, setInputEndDate] = useState(todayEnd);
  const [techs, setTechs] = useState([]);
  const chartRef = useRef();
  const [dateRangeType, setDateRangeType] = useState("today");
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [timeAgo, setTimeAgo] = useState("");
  const [showReportsDrawer, setShowReportsDrawer] = useState(false);
  const dateRangeOptions = [
    {
      label: `Hoje (${new Date().toLocaleDateString("pt-BR")})`,
      value: "today"
    },
    {
      label: `Ontem (${new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toLocaleDateString("pt-BR")})`,
      value: "yesterday"
    },
    { label: "Última Semana", value: "lastWeek" },
    { label: "Últimos 30 dias", value: "last30Days" },
    { label: "Este Mês", value: "thisMonth" },
    { label: "Mês Passado", value: "lastMonth" },
    { label: "Este Ano", value: "thisYear" },
    { label: "Personalizado", value: "custom" }
  ];

  const handleDateRangeChange = (e) => {
    const value = e.value;
    setDateRangeType(value);

    const today = new Date();
    const today2 = new Date();
    let start = new Date();
    let end = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    const yesterday2 = new Date(today2.setDate(today2.getDate() - 1));
    const yesterdayStart = new Date(yesterday.setHours(-3, 0, 0, 0));
    const yesterdayEnd = new Date(yesterday2.setHours(20, 59, 59, 999));

    const today3 = new Date(today.setDate(today.getDate()));
    const today4 = new Date(today.setDate(today.getDate()));
    today3.setHours(0, 0, 0, 0);
    today4.setHours(0, 0, 0, 0);
    const todayStart = new Date(today3.setHours(-3, 0, 0, 0));
    // todayStart.setUTCHours(0, 0, 0, 0);
    const todayEnd = new Date(today4.setHours(20, 59, 59, 999));

    switch (value) {
      case "today":
        start = todayStart;
        end = todayEnd;
        break;
      case "yesterday":
        start = yesterdayStart;
        end = yesterdayEnd;
        break;
      case "lastWeek":
        start = new Date(today.setDate(today.getDate() - 7));
        end = new Date();
        break;
      case "last30Days":
        start = new Date(today.setDate(today.getDate() - 30));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date();
        break;
      case "lastMonth":
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case "thisYear":
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date();
        break;
      case "custom":
        return;
      default:
        start = todayStart;
        end = todayEnd;
        break;
    }

    setInputBeginDate(start);
    setBeginDate(start);
    setInputEndDate(end);
    setEndDate(end);
  };

  const { data: techsData, isLoading: isLoadingTechs } = useQuery({
    queryFn: async () => {
      return await api.get("/users/getTechs");
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ["techs"],
    onSuccess: (data) => {
      if (data?.data) {
        setTecnicos(
          data.data.filter((user) => user.user_type === "Tech") || []
        );
      }
    }
  });

  const {
    data: reportsCountData,
    isLoading: isLoadingReports1,
    isFetching: isFetchingReports,
    refetch: refetchReportsCount
  } = useQuery({
    queryFn: async () => {
      // const startOfDay = new Date(beginDate);
      // startOfDay.setHours(0, 0, 0, 0);

      // const endOfDay = new Date(endDate);
      // endOfDay.setHours(20, 59, 59, 999);

      return await api.post(`/reports/dashboard/reports-count`, {
        beginDate: beginDate,
        endDate: endDate,
        createdBy: techs.length > 0 ? techs.join(",") : ""
      });
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ["reports-count", techs, beginDate, endDate]
  });

  const isLoadingReports = isLoadingReports1 || isFetchingReports;

  const {
    data: serviceOrderStatusCountData,
    isLoading: isLoadingServiceOrders1,
    isFetching: isFetchingServiceOrders,
    refetch: refetchServiceOrderStatusCount
  } = useQuery({
    queryFn: async () => {
      return await api.get(
        `/service_order/getStatusCount?beginDate=${beginDate
          .toISOString()
          .slice(0, 10)}&endDate=${
          endDate ? endDate?.toISOString().slice(0, 10) : ""
        }&createdBy=${techs.join(",")}`
      );
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ["servicer-order-status-count", techs, beginDate, endDate]
  });
  const isLoadingServiceOrders =
    isLoadingServiceOrders1 || isFetchingServiceOrders;
  const { data: displacementData, refetch: refetchDisplacementData } = useQuery(
    {
      queryFn: async () => {
        return await api.get(
          `/reports/dashboard/displacement-time?createdBy=${techs.join(
            ","
          )}&beginDate=${beginDate.toISOString().slice(0, 10)}&endDate=${
            endDate ? endDate?.toISOString().slice(0, 10) : ""
          }`
        );
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      queryKey: ["displacement-time", techs, beginDate, endDate]
    }
  );

  const { data: warrantyData, refetch: refetchWarrantyData } = useQuery({
    queryFn: async () => {
      return await api.get(
        `/reports/dashboard/warranty-hours?createdBy=${techs.join(
          ","
        )}&beginDate=${beginDate.toISOString().slice(0, 10)}&endDate=${
          endDate ? endDate?.toISOString().slice(0, 10) : ""
        }`
      );
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ["warranty-hours", techs, beginDate, endDate]
  });

  const {
    data: serviceOrderWorkedTimeData,
    refetch: refetchServiceOrderWorkedTime
  } = useQuery({
    queryFn: async () => {
      return await api.get(
        `/service_order/dash/getWorkedTime?beginDate=${beginDate
          .toISOString()
          .slice(0, 10)}&endDate=${
          endDate ? endDate?.toISOString().slice(0, 10) : ""
        }&createdBy=${techs.join(",")}`
      );
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ["servicer-order-worked-time", beginDate, endDate, techs]
  });

  const {
    data: dailyHoursData,
    isLoading: isLoadingDailyHours1,
    isFetching: isFetchingDailyHours,
    refetch: refetchDailyHours
  } = useQuery({
    queryFn: async () => {
      // const startOfDay = new Date(beginDate);
      // startOfDay.setHours(0, 0, 0, 0);

      // const endOfDay = new Date(endDate);
      // endOfDay.setHours(23, 59, 59, 999);

      return await api.post(`/reports/dashboard/daily-hours`, {
        beginDate,
        endDate,
        createdBy: techs.length > 0 ? techs.join(",") : ""
      });
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ["daily-hours", techs, beginDate, endDate]
  });
  const isLoadingDailyHours = isLoadingDailyHours1 || isFetchingDailyHours;

  const reportsCount = reportsCountData?.data?.reports_count;
  const dailyHours = dailyHoursData?.data?.daily_hours;

  const getDailyHoursDataByType = useMemo(() => {
    if (!dailyHours) return [];
    switch (selectedReportType) {
      case "all":
        return dailyHours?.all || [];
      case "correctives":
        return dailyHours?.correctives || [];
      case "preventives":
        return [
          ...(dailyHours?.preventives || []),
          ...(dailyHours?.preventivesCombustions || [])
        ];
      default:
        return dailyHours?.all || [];
    }
  }, [dailyHours, selectedReportType]);

  const dailyHoursSelected = getDailyHoursDataByType;

  const dailyHoursChartData = {
    labels: dailyHoursSelected
      .filter(
        (hour) =>
          (hour.going_seconds || 0) +
            (hour.returning_seconds || 0) -
            (hour.time_paused_in_displacement || 0) >
          0
      )
      .map((hour) => hour.name),
    datasets: [
      {
        label: "Horas em Deslocamento (Ida + Volta)",
        backgroundColor: "rgba(243, 200, 66, 0.2)",
        borderColor: "#f3c842",
        borderWidth: 2,
        data: dailyHoursSelected
          .filter(
            (hour) =>
              (hour.going_seconds || 0) +
                (hour.returning_seconds || 0) -
                (hour.time_paused_in_displacement || 0) >
              0
          )
          .map(
            (hour) =>
              (hour.going_seconds || 0) +
              (hour.returning_seconds || 0) -
              (hour.time_paused_in_displacement || 0)
          ),
        fill: true,
        datalabels: {
          align: "end",
          anchor: "end",
          formatter: (value) => {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours}h${minutes}`;
          }
        }
      }
    ]
  };

  const dailyHoursChartOptions = {
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours}h ${minutes}min`;
          }
        },
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        padding: 12,
        titleFont: { size: 14 },
        bodyFont: { size: 13 }
      },
      datalabels: {
        color: "#666666",
        font: {
          size: 12
        },
        padding: 6
      }
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0, 0, 0, 0.05)"
        },
        ticks: {
          callback: function (value) {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours}h${minutes}`;
          },
          font: { size: 12 }
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          font: { size: 12 }
        }
      }
    }
  };

  const dailyHoursInAttendanceChartData = {
    labels: dailyHoursSelected.map((hour) => hour.name),
    datasets: [
      {
        label: "Horas em Atendimento",
        backgroundColor: "rgba(76, 175, 80, 0.2)",
        borderColor: "#4CAF50",
        borderWidth: 2,
        data: dailyHoursSelected.map(
          (hour) =>
            (hour.attendance_seconds || 0) -
            (hour.time_paused_in_attendance || 0)
        ),
        fill: true,
        datalabels: {
          align: "end",
          anchor: "end",
          formatter: (value) => {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours}h${minutes}`;
          }
        }
      }
    ]
  };

  const reportOptionsChartData = {
    labels: dailyHoursSelected.map((hour) => hour.name.split(" ")[0]),
    datasets: [
      {
        label: "Quantidade",
        data: dailyHoursSelected.map((hour) => hour.count),
        backgroundColor: [
          "#2196F3",
          "#9C27B0",
          "#4CAF50",
          "#FF9800",
          "#f44336",
          "#FAC02D",
          "#00BCD4",
          "#E91E63"
        ],
        datalabels: {
          align: "end",
          anchor: "end",
          formatter: (value) => value
        }
      }
    ]
  };

  const reportQuantityChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        padding: 12,
        titleFont: { size: 14 },
        bodyFont: { size: 13 }
      },
      datalabels: {
        color: "#666666",
        font: {
          size: 12
        },
        padding: 6
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.05)"
        },
        ticks: {
          font: { size: 12 }
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          font: { size: 12 }
        }
      }
    }
  };

  const workedTimeChartData = {
    labels:
      serviceOrderWorkedTimeData?.data?.by_user?.map(
        (user) => user.created_by.name
      ) || [],
    datasets: [
      {
        label: "Horas Trabalhadas",
        backgroundColor: "rgba(156, 39, 176, 0.2)",
        borderColor: "#9C27B0",
        borderWidth: 2,
        data:
          serviceOrderWorkedTimeData?.data?.by_user?.map((user) => {
            const [hours, minutes] = user.worked_time.split(":");
            return Number(hours) + Number(minutes) / 60;
          }) || [],
        fill: true,
        datalabels: {
          align: "end",
          anchor: "end",
          formatter: (value) => {
            const hours = Math.floor(value);
            const minutes = Math.round((value - hours) * 60);
            return `${hours}h${minutes}`;
          }
        }
      }
    ]
  };

  const workedTimeChartOptions = {
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw;
            const hours = Math.floor(value);
            const minutes = Math.round((value - hours) * 60);
            return `${hours}h ${minutes}min`;
          }
        },
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        padding: 12,
        titleFont: { size: 14 },
        bodyFont: { size: 13 }
      },
      datalabels: {
        color: "#666666",
        font: {
          size: 12
        },
        padding: 6
      }
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0, 0, 0, 0.05)"
        },
        ticks: {
          callback: function (value) {
            const hours = Math.floor(value);
            const minutes = Math.round((value - hours) * 60);
            return `${hours}h${minutes}`;
          },
          font: { size: 12 }
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          font: { size: 12 }
        }
      }
    }
  };

  const handleSelectedTech = (id) => {
    if (techs.includes(id)) {
      setTechs(techs.filter((tech) => tech !== id));
      return;
    }
    setTechs([...techs, id]);
  };

  const handleSumAllServiceOrders = () => {
    return (
      serviceOrderStatusCountData?.data?.closed +
      serviceOrderStatusCountData?.data?.running +
      serviceOrderStatusCountData?.data?.opened
    );
  };

  const handleSelectAllTechs = () => {
    if (techs.length === tecnicos.length) {
      setTechs([]);
    } else {
      setTechs(tecnicos.map((tech) => tech.id));
    }
  };

  const CardSkeleton = () => (
    <div className="quantity-card-skeleton">
      <Skeleton width="48px" height="48px" borderRadius="12px" />
      <div className="card-content-skeleton">
        <Skeleton width="100px" height="35px" />
        <Skeleton width="80px" height="40px" />
      </div>
    </div>
  );

  const TechCardSkeleton = () => (
    <div className="tech-card-skeleton">
      <Skeleton shape="circle" width="40px" height="40px" />
      <div style={{ flex: 1 }}>
        <Skeleton width="100px" height="24px" />
      </div>
    </div>
  );

  const ChartSkeleton = () => (
    <div className="chart-card">
      <div className="chart-header">
        <Skeleton width="200px" height="24px" />
        <Skeleton width="100px" height="24px" />
      </div>
      <div className="chart-content-skeleton">
        <Skeleton width="100%" height="350px" />
      </div>
    </div>
  );

  const formatTechName = (fullName) => {
    if (!fullName) return "";
    const names = fullName.split(" ");
    if (names.length === 1) return names[0];
    return `${names[0]} ${names[1]?.[0] || ""}`;
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
    });
  };

  const refetch = () => {
    refetchServiceOrderWorkedTime();
    refetchDailyHours();
    refetchReportsCount();
    refetchServiceOrderStatusCount();
    refetchDisplacementData();
    refetchWarrantyData();
  };

  const getDateRangeText = () => {
    if (dateRangeType === "custom") return null;

    return (
      <div className="date-range-text">
        <i className="pi pi-calendar" />
        <span>
          {formatDate(beginDate)} - {formatDate(endDate)}
        </span>
      </div>
    );
  };

  const selectedTechTemplate = (techs) => {
    if (!techs || techs.length === 0) {
      return <span className="tech-placeholder">Selecione os técnicos</span>;
    }
    return (
      <div className="tech-chips">
        {techs.map((techId) => {
          const tech = tecnicos.find((t) => t.id === techId);
          if (!tech) return null;
          return (
            <div key={tech.id} className="tech-chip">
              <span className="tech-chip-avatar">{tech.name?.[0]}</span>
              <span className="tech-chip-name">
                {formatTechName(tech.name)}
              </span>
              <i
                className="pi pi-times tech-chip-remove"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectedTech(tech.id);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const exportToCSV = () => {
    const data = dailyHoursSelected.map((tech) => ({
      Técnico: tech.name,
      "Horas Trabalhadas": secondsToHoursMinutes(tech.total_seconds),
      "Horas em Atendimento": secondsToHoursMinutes(tech.attendance_seconds),
      "Quantidade de Relatórios": tech.count
    }));

    const headers = Object.keys(data[0]);
    const csvContent = [
      headers.join(","),
      ...data.map((row) =>
        headers.map((header) => `"${row[header]}"`).join(",")
      )
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `dashboard-${formatDate(beginDate)}-${formatDate(endDate)}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTimeAgo(getTimeAgo(lastUpdate));
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [lastUpdate]);

  const handleRefresh = () => {
    refetch();
    setTimeAgo(getTimeAgo(lastUpdate));
    setLastUpdate(new Date());
  };

  const getTotalWorkedTime = () => {
    if (!serviceOrderWorkedTimeData?.data?.by_user) return "0h 0min";

    const totalSeconds = serviceOrderWorkedTimeData.data.by_user.reduce(
      (total, user) => {
        const [hours, minutes] = user.worked_time.split(":");
        return total + (Number(hours) * 3600 + Number(minutes) * 60);
      },
      0
    );

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);

    return `${hours}h ${minutes}min`;
  };

  const pausedTimeChartData = {
    labels: dailyHoursSelected.map((hour) => hour.name),
    datasets: [
      {
        label: "Tempo Pausado",
        backgroundColor: "rgba(96, 125, 139, 0.2)",
        borderColor: "#607D8B",
        borderWidth: 2,
        data: dailyHoursSelected.map((hour) => hour.paused_time_seconds),
        fill: true,
        datalabels: {
          align: "end",
          anchor: "end",
          formatter: (value) => {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours}h${minutes}`;
          }
        }
      }
    ]
  };

  const sortedTecnicos = useMemo(() => {
    return [...tecnicos].sort((a, b) => a.name?.localeCompare(b.name || ""));
  }, [tecnicos]);

  return (
    <div>
      <div className="dashboard-disclaimer tech-filter-info">
        {" "}
        <i className="pi pi-info-circle" />
        <span>Cards com * são afetados pelo filtro de técnico</span>
      </div>

      <div className="dashboard-refresh flex align-items-center">
        <div>
          <Button
            label="Ver relatórios"
            icon="pi pi-list"
            className="p-button-outlined p-button-info me-2"
            onClick={() => setShowReportsDrawer(true)}
          />
        </div>
        <div className="refresh-info">
          {/* <span className="last-update">Última atualização: {timeAgo}</span> */}
          <span>Atualizar dados</span>
          <Button
            icon="pi pi-refresh"
            className="refresh-button p-button-outlined"
            onClick={handleRefresh}
            loading={
              isLoadingDailyHours || isLoadingReports || isLoadingServiceOrders
            }
            tooltip="Atualizar dados"
            tooltipOptions={{ position: "bottom" }}
          />
        </div>
      </div>

      <div className="dashboard-cards">
        {isLoadingReports || isLoadingServiceOrders ? (
          [...Array(11)].map((_, index) => <CardSkeleton key={index} />)
        ) : (
          <>
            <QuantityCard
              label="Quantidade de Relatórios *"
              color="#2196F3"
              value={reportsCount?.all ?? 0}
              icon="pi pi-file"
            />
            <QuantityCard
              label="Quantidade de Corretivas *"
              color="#9C27B0"
              value={reportsCount?.correctives ?? 0}
              icon="pi pi-cog"
              isSelected={selectedReportType === "correctives"}
              clickable={true}
              onClick={() => {
                if (selectedReportType === "correctives") {
                  setSelectedReportType("all");
                } else {
                  setSelectedReportType("correctives");
                }
              }}
            />
            <QuantityCard
              label="Quantidade de Preventivas *"
              color="#4CAF50"
              value={
                (reportsCount?.preventives ?? 0) +
                (reportsCount?.preventives_combustions ?? 0)
              }
              icon="pi pi-shield"
              isSelected={selectedReportType === "preventives"}
              onClick={() => {
                if (selectedReportType === "preventives") {
                  setSelectedReportType("all");
                } else {
                  setSelectedReportType("preventives");
                }
              }}
            />
            <QuantityCard
              label="Hora em Atendimento *"
              color="#FF9800"
              value={secondsToHoursMinutes(
                dailyHours?.all?.reduce(
                  (sum, obj) => sum + (obj.worked_time_without_pause || 0),
                  0
                )
              )}
              icon="pi pi-clock"
            />
            <QuantityCard
              label="Tempo pausado *"
              color="#607D8B"
              value={secondsToHoursMinutes(
                dailyHours?.all?.reduce(
                  (sum, obj) => sum + (obj.paused_time_seconds || 0),
                  0
                )
              )}
              icon="pi pi-pause"
            />
            <QuantityCard
              label="Ordens de Serviço abertas"
              color="#f44336"
              value={serviceOrderStatusCountData?.data?.opened ?? 0}
              icon="pi pi-folder-open"
            />
            <QuantityCard
              label="Ordens de Serviço em andamento"
              color="#FAC02D"
              value={serviceOrderStatusCountData?.data?.running ?? 0}
              icon="pi pi-sync"
            />
            <QuantityCard
              label="Ordens de Serviço fechadas"
              color="#4CAF50"
              value={serviceOrderStatusCountData?.data?.closed ?? 0}
              icon="pi pi-check"
            />
            <QuantityCard
              label="Total de Ordens de Serviço"
              color="#00BCD4"
              value={handleSumAllServiceOrders() ?? 0}
              icon="pi pi-inbox"
            />
            <QuantityCard
              label="Horas trabalhadas em Ordens de Serviço *"
              color="#9C27B0"
              value={getTotalWorkedTime()}
              icon="pi pi-clock"
            />
            <QuantityCard
              label="Hora deslocamento *"
              color="#795548"
              value={displacementData?.data?.total_displacement_time ?? 0}
              icon="pi pi-map"
            />
            <QuantityCard
              label="Retrabalho + Garantia *"
              color="#E91E63"
              value={warrantyData?.data?.total_hours ?? 0}
              icon="pi pi-refresh"
            />
          </>
        )}
      </div>

      <div className="floating-filters">
        <div className="floating-filters-content">
          <div className="floating-date-filter">
            {getDateRangeText()}
            <Dropdown
              value={dateRangeType}
              options={dateRangeOptions}
              onChange={handleDateRangeChange}
              className="date-range-dropdown"
            />
            {dateRangeType === "custom" && (
              <DateRangeDashboard
                inputBeginDate={inputBeginDate}
                beginDate={beginDate}
                setInputBeginDate={(e) => {
                  const date = e.value || e;
                  if (date) {
                    setInputBeginDate(date);
                    const day = new Date(date);
                    day.setHours(-3, 0, 0, 0);
                    setBeginDate(day);
                  }
                }}
                setBeginDate={(e) => {
                  const date = e.value || e;
                  if (date) {
                    setInputBeginDate(date);
                    const day = new Date(date);
                    day.setHours(-3, 0, 0, 0);
                    setBeginDate(day);
                  }
                }}
                setEndDate={(e) => {
                  const date = e.value || e;
                  if (date) {
                    setInputEndDate(date);
                    const day = new Date(date);
                    day.setHours(20, 59, 59, 999);
                    setEndDate(day);
                  }
                }}
                inputEndDate={inputEndDate}
                setInputEndDate={(e) => {
                  const date = e.value || e;
                  if (date) {
                    setInputEndDate(date);
                    const day = new Date(date);
                    day.setHours(20, 59, 59, 999);
                    setEndDate(day);
                  }
                }}
              />
            )}
          </div>

          <div className="filter-divider" />

          <div className="floating-tech-filter">
            <div className="tech-filter-label">
              <i className="pi pi-users" />
              <span>Técnicos</span>
            </div>
            <MultiSelect
              value={techs}
              options={sortedTecnicos}
              onChange={(e) => setTechs(e.value)}
              optionLabel="name"
              optionValue="id"
              filter
              showClear
              valueTemplate={selectedTechTemplate}
              itemTemplate={(option) => (
                <div className="tech-item">
                  <span className="tech-item-avatar">{option.name?.[0]}</span>
                  <span className="tech-item-name">
                    {formatTechName(option.name)}
                  </span>
                </div>
              )}
              placeholder="Filtrar por técnicos"
              className="tech-multiselect"
            />
          </div>
        </div>
      </div>

      <div
        className={`charts-container ${
          dateRangeType === "custom" ? "with-custom-date" : ""
        }`}
      >
        <div className="charts-grid">
          {isLoadingDailyHours ? (
            [...Array(4)].map((_, index) => <ChartSkeleton key={index} />)
          ) : (
            <>
              <div className="chart-card">
                <div className="chart-header">
                  <h3>Horas em deslocamento</h3>
                  <div className="chart-legend">
                    <i className="pi pi-clock" style={{ color: "#f3c842" }} />
                    <span>Por técnico</span>
                  </div>
                </div>
                <div className="chart-content">
                  <Chart
                    type="bar"
                    data={dailyHoursChartData}
                    options={dailyHoursChartOptions}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>

              <div className="chart-card">
                <div className="chart-header">
                  <h3>Horas em Atendimento</h3>
                  <div className="chart-legend">
                    <i className="pi pi-user" style={{ color: "#4CAF50" }} />
                    <span>Por técnico</span>
                  </div>
                </div>
                <div className="chart-content">
                  <Chart
                    type="bar"
                    data={dailyHoursInAttendanceChartData}
                    options={dailyHoursChartOptions}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>

              <div className="chart-card">
                <div className="chart-header">
                  <h3>Quantidade de Relatórios</h3>
                  <div className="chart-legend">
                    <i className="pi pi-file" style={{ color: "#2196F3" }} />
                    <span>Por técnico</span>
                  </div>
                </div>
                <div className="chart-content">
                  <Chart
                    type="bar"
                    data={reportOptionsChartData}
                    options={reportQuantityChartOptions}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>

              <div className="chart-card">
                <div className="chart-header">
                  <h3>Horas Trabalhadas em OS</h3>
                  <div className="chart-legend">
                    <i className="pi pi-clock" style={{ color: "#9C27B0" }} />
                    <span>Por técnico</span>
                  </div>
                </div>
                <div className="chart-content">
                  <Chart
                    type="bar"
                    data={workedTimeChartData}
                    options={workedTimeChartOptions}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>

              <div className="chart-card">
                <div className="chart-header">
                  <h3>Tempo Pausado</h3>
                  <div className="chart-legend">
                    <i className="pi pi-pause" style={{ color: "#607D8B" }} />
                    <span>Por técnico</span>
                  </div>
                </div>
                <div className="chart-content">
                  <Chart
                    type="bar"
                    data={pausedTimeChartData}
                    options={dailyHoursChartOptions}
                    plugins={[ChartDataLabels]}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <ReportsDrawer
        visible={showReportsDrawer}
        onHide={() => setShowReportsDrawer(false)}
        beginDate={beginDate.toISOString().slice(0, 10)}
        endDate={endDate.toISOString().slice(0, 10)}
        techs={techs}
      />
    </div>
  );
}
