import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import api from "../../services/api";
import "./styles.css";

const statusOptions = [
  { value: "Ok", label: "Ok" },
  { value: "Nao Ok", label: "Não Ok" },
  { value: "N/A", label: "N/A" }
];

const equipmentTypes = [
  { value: "bateria", label: "Bateria" },
  { value: "carregador", label: "Carregador" },
  { value: "estrado", label: "Estrado" },
  {
    value: "carrinho_para_troca_de_bateria",
    label: "Carrinho para Troca de Bateria"
  },
  { value: "paleteira", label: "Paleteira" },
  { value: "cilindro_de_gas", label: "Cilindro de Gás" }
];

const compressImage = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");

        // Calculate new dimensions while maintaining aspect ratio
        let width = img.width;
        let height = img.height;
        const maxSize = 1200; // Maximum dimension size

        if (width > height && width > maxSize) {
          height = Math.round((height * maxSize) / width);
          width = maxSize;
        } else if (height > maxSize) {
          width = Math.round((width * maxSize) / height);
          height = maxSize;
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        // Convert to blob with reduced quality
        canvas.toBlob(
          (blob) => {
            resolve(
              new File([blob], file.name, {
                type: "image/jpeg",
                lastModified: Date.now()
              })
            );
          },
          "image/jpeg",
          0.5 // Quality (0.5 = 50% quality)
        );
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });
};

const ChecklistForm = ({
  visible = false,
  onHide = () => {},
  currentChecklist = null,
  loading = false,
  toast,
  onSave = () => {}
}) => {
  const [companyId, setCompanyId] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [equipmentId, setEquipmentId] = useState("");
  const [equipmentName, setEquipmentName] = useState("");
  const [companies, setCompanies] = useState([]);
  const [selectEquipments, setSelectEquipments] = useState([]);
  const [allEquipments, setAllEquipments] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [disableEquipment, setDisableEquipment] = useState(true);

  // Checklist items state
  const [checklistItems, setChecklistItems] = useState([
    {
      checklist_item: "Adesivo Patrimônio",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Adesivo MOV",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Limpeza Geral",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Pintura",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Testes de Acionamento",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Danos Lateral Direita",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Danos Lateral Esquerda",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Danos Frente",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Danos Traseira",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Danos Interior Equipamento",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Danos Garfos",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Painel de Instrumentos",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Extintor",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Retrovisor",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Kit Iluminação e Sinalização",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Rodas e Pneus",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Torre de Elevação",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Sistemas de Segurança e Proteção",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Sistema de Freios",
      value: "N/A",
      observation: null
    },
    {
      checklist_item: "Sistema de Direção",
      value: "N/A",
      observation: null
    }
  ]);

  // Associated equipments state
  const [associatedEquipments, setAssociatedEquipments] = useState([]);
  const [availableEquipments, setAvailableEquipments] = useState([]);

  // Add a state to track when data is fully loaded for editing
  const [dataLoaded, setDataLoaded] = useState(false);

  const [images, setImages] = useState({
    frontal: null,
    traseira: null,
    lado_direito: null,
    lado_esquerdo: null,
    painel_com_horimetro: null,
    cockpit: null
  });
  const [extraImages, setExtraImages] = useState([]);
  const [extraImageCount, setExtraImageCount] = useState(0);

  // Add alongside other state declarations
  const [imageUrls, setImageUrls] = useState({
    frontal: null,
    traseira: null,
    lado_direito: null,
    lado_esquerdo: null,
    painel_com_horimetro: null,
    cockpit: null
  });
  const [extraImageUrls, setExtraImageUrls] = useState([]);

  // Add a new state to track which images are from the server
  const [existingExtraImageIndexes, setExistingExtraImageIndexes] = useState(
    new Set()
  );

  useEffect(() => {
    if (visible) {
      setDataLoaded(false); // Reset data loaded state when dialog opens
      fetchCompanies();
      fetchAllEquipments();
    }
  }, [visible]);

  useEffect(() => {
    if (currentChecklist && companies.length > 0 && allEquipments.length > 0) {
      populateFormWithChecklistData();
      setDataLoaded(true);
    } else if (
      !currentChecklist &&
      companies.length > 0 &&
      allEquipments.length > 0
    ) {
      setDataLoaded(true);
    }
  }, [currentChecklist, companies, allEquipments]);

  const fetchCompanies = async () => {
    try {
      const response = await api.get("/companies/all");
      setCompanies(
        response.data.map((company) => ({
          value: company.id,
          label: company.name,
          original: company
        }))
      );
    } catch (error) {
      console.error("Error fetching companies:", error);
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao carregar empresas",
        life: 3000
      });
      setDataLoaded(false); // Set dataLoaded to false if there's an error
    }
  };

  const fetchAllEquipments = async () => {
    try {
      const response = await api.get("/equipments/all");

      const formattedEquipments = response.data.map((equipment) => ({
        value: equipment.id,
        label: `${equipment.equipment} | ${equipment.brand?.name} | ${equipment.model?.name}`,
        original: equipment
      }));

      setAllEquipments(formattedEquipments);
      setAvailableEquipments(formattedEquipments);
    } catch (error) {
      console.error("Error fetching equipments:", error);
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao carregar equipamentos",
        life: 3000
      });
      setDataLoaded(false); // Set dataLoaded to false if there's an error
    }
  };

  const handleChangeSelect = (selectedOption) => {
    setEquipmentId("");
    setEquipmentName("");
    setDisableEquipment(true);

    setCompanyId(selectedOption.value);

    // Find the selected company in the companies list
    const company = companies.find((c) => c.value === selectedOption.value);
    if (company) {
      setSelectedCompany(company.original);

      // Set up company equipments for main equipment dropdown
      if (
        company.original.equipments &&
        company.original.equipments.length > 0
      ) {
        const equipmentOptions = company.original.equipments.map(
          (equipment) => ({
            value: equipment.id,
            label: `${equipment.equipment} | ${equipment.brand?.name} | ${equipment.model?.name}`
          })
        );

        setSelectEquipments(equipmentOptions);
        setDisableEquipment(false);
      } else {
        setSelectEquipments([]);
        setDisableEquipment(true);
      }
    } else {
      setSelectedCompany(null);
      setSelectEquipments([]);
      setDisableEquipment(true);
    }
  };

  const handleChangeSelectEquipment = (selectedOption) => {
    setEquipmentId(selectedOption.value);
    selectEquipments.forEach((e) => {
      if (e.value === selectedOption.value) {
        setEquipmentName(e.label);
        return;
      }
    });
  };

  const populateFormWithChecklistData = () => {
    if (!currentChecklist || companies.length === 0) return;

    setCompanyId(currentChecklist.company_id);

    // Find and set company and equipment data
    const company = companies.find(
      (c) => c.value === currentChecklist.company_id
    );
    if (company) {
      setSelectedCompany(company.original);

      // Set up company equipments for main equipment dropdown
      if (
        company.original.equipments &&
        company.original.equipments.length > 0
      ) {
        const equipmentOptions = company.original.equipments.map(
          (equipment) => ({
            value: equipment.id,
            label: `${equipment.equipment} | ${equipment.brand?.name} | ${equipment.model?.name}`
          })
        );

        setSelectEquipments(equipmentOptions);
        setDisableEquipment(false);
      }

      // Find the equipment in the company's equipment list
      const equipment = company.original.equipments?.find(
        (e) => e.id === currentChecklist.equipment_id
      );

      if (equipment) {
        setEquipmentId(equipment.id);
        setEquipmentName(
          `${equipment.equipment} | ${equipment.brand?.name} | ${equipment.model?.name}`
        );
      }
    }

    // Set checklist items
    if (currentChecklist.checklist_items) {
      setChecklistItems(
        currentChecklist.checklist_items.map((item) => ({
          checklist_item: item.checklist_item,
          value: item.value,
          observation: item.observation
        }))
      );
    }

    // Set associated equipments
    if (currentChecklist.checklist_equipments) {
      setAssociatedEquipments(
        currentChecklist.checklist_equipments.map((item) => ({
          equipment_id: item.equipment_id,
          type: item.type,
          status: item.status === 1,
          observation: item.observation
        }))
      );
    }

    // Set images URLs for required images
    const newImageUrls = { ...imageUrls };
    const newExtraUrls = [];
    const newExtraImages = [];
    const existingIndexes = new Set();

    if (currentChecklist.images) {
      currentChecklist.images.forEach((image) => {
        if (image.type === "extra") {
          newExtraUrls.push(image.url);
          newExtraImages.push(null);
          existingIndexes.add(newExtraUrls.length - 1); // Track the index of existing images
        } else {
          newImageUrls[image.type] = image.url;
        }
      });
    }

    setImageUrls(newImageUrls);
    setExtraImageUrls(newExtraUrls);
    setExtraImages(newExtraImages);
    setExtraImageCount(newExtraUrls.length);
    setExistingExtraImageIndexes(existingIndexes);
  };

  const resetForm = () => {
    setCompanyId("");
    setSelectedCompany(null);
    setEquipmentId("");
    setEquipmentName("");
    setDisableEquipment(true);
    setSelectEquipments([]);
    setChecklistItems([
      {
        checklist_item: "Adesivo Patrimônio",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Adesivo MOV",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Limpeza Geral",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Pintura",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Testes de Acionamento",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Danos Lateral Direita",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Danos Lateral Esquerda",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Danos Frente",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Danos Traseira",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Danos Interior Equipamento",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Danos Garfos",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Painel de Instrumentos",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Extintor",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Retrovisor",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Kit Iluminação e Sinalização",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Rodas e Pneus",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Torre de Elevação",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Sistemas de Segurança e Proteção",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Sistema de Freios",
        value: "N/A",
        observation: null
      },
      {
        checklist_item: "Sistema de Direção",
        value: "N/A",
        observation: null
      }
    ]);
    setAssociatedEquipments([]);
    setImages({
      frontal: null,
      traseira: null,
      lado_direito: null,
      lado_esquerdo: null,
      painel_com_horimetro: null,
      cockpit: null
    });
    setImageUrls({
      frontal: null,
      traseira: null,
      lado_direito: null,
      lado_esquerdo: null,
      painel_com_horimetro: null,
      cockpit: null
    });
    setExtraImages([]);
    setExtraImageUrls([]);
    setExtraImageCount(0);
    setExistingExtraImageIndexes(new Set());
  };

  const addAssociatedEquipment = () => {
    setAssociatedEquipments([
      ...associatedEquipments,
      {
        equipment_id: "",
        type: "",
        status: true,
        observation: ""
      }
    ]);
  };

  const removeAssociatedEquipment = (index) => {
    const newEquipments = [...associatedEquipments];
    newEquipments.splice(index, 1);
    setAssociatedEquipments(newEquipments);
  };

  const updateAssociatedEquipment = (index, field, value) => {
    const newEquipments = [...associatedEquipments];
    newEquipments[index][field] = value;
    setAssociatedEquipments(newEquipments);
  };

  const handleImageChange = async (type, file) => {
    if (file) {
      // Validate file type
      if (!["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "O arquivo deve ser uma imagem (JPG, JPEG ou PNG)",
          life: 3000
        });
        return;
      }

      try {
        const compressedFile = await compressImage(file);
        setImages((prev) => ({
          ...prev,
          [type]: compressedFile
        }));
      } catch (error) {
        console.error("Error compressing image:", error);
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Erro ao processar imagem",
          life: 3000
        });
      }
    }
  };

  const addExtraImage = () => {
    setExtraImageCount((prev) => prev + 1);
    setExtraImages((prev) => [...prev, null]);
  };

  const removeExtraImage = (index) => {
    if (existingExtraImageIndexes.has(index)) {
      // Don't allow removal of existing images
      toast.current.show({
        severity: "warn",
        summary: "Aviso",
        detail: "Não é possível remover imagens existentes durante a edição",
        life: 3000
      });
      return;
    }

    setExtraImages((prev) => prev.filter((_, i) => i !== index));
    setExtraImageUrls((prev) => prev.filter((_, i) => i !== index));
    setExtraImageCount((prev) => prev - 1);
  };

  const handleExtraImageChange = async (index, file) => {
    if (file) {
      // Validate file type
      if (!["image/jpeg", "image/png", "image/jpg"].includes(file.type)) {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "O arquivo deve ser uma imagem (JPG, JPEG ou PNG)",
          life: 3000
        });
        return;
      }

      try {
        const compressedFile = await compressImage(file);
        setExtraImages((prev) => {
          const newImages = [...prev];
          newImages[index] = compressedFile;
          return newImages;
        });
      } catch (error) {
        console.error("Error compressing image:", error);
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Erro ao processar imagem",
          life: 3000
        });
      }
    }
  };

  const handleSubmit = async () => {
    if (!companyId || !equipmentId) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Empresa e equipamento são obrigatórios",
        life: 3000
      });
      return;
    }

    // Validate required images - check both new files and existing URLs
    const missingImages = Object.entries(images)
      .filter(([type, file]) => !file && !imageUrls[type])
      .map(([type]) => type);

    if (missingImages.length > 0) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `As seguintes imagens são obrigatórias: ${missingImages.join(
          ", "
        )}`,
        life: 3000
      });
      return;
    }

    setFormLoading(true);

    try {
      const formData = new FormData();

      // Add JSON data
      const payload = {
        company_id: companyId,
        equipment_id: equipmentId,
        equipments: associatedEquipments.map((item) => ({
          equipment_id: item.equipment_id,
          type: item.type,
          status: item.status,
          observation: item.status ? null : item.observation
        })),
        checklist_items: checklistItems
      };
      formData.append("data", JSON.stringify(payload));

      // Add only the new images that were uploaded
      Object.entries(images).forEach(([type, file]) => {
        if (file) {
          formData.append(type, file);
        }
      });

      // Add new extra images
      extraImages.forEach((file, index) => {
        if (file) {
          formData.append(`extra_${index + 1}`, file);
        }
      });

      let response;
      if (currentChecklist && currentChecklist.id) {
        // For update, we use POST with _method: PUT
        formData.append("_method", "PUT");
        response = await api.post(
          `/checklist/${currentChecklist.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
        toast.current.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Checklist atualizado com sucesso",
          life: 3000
        });
      } else {
        response = await api.post("/checklist", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        toast.current.show({
          severity: "success",
          summary: "Sucesso",
          detail: "Checklist criado com sucesso",
          life: 3000
        });
      }

      onSave();
      onHide();
    } catch (error) {
      console.error("Error saving checklist:", error);
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: error.response?.data?.message || "Erro ao salvar checklist",
        life: 3000
      });
    } finally {
      setFormLoading(false);
    }
  };

  const templateSelectPrime = (option) => {
    return <div style={{ maxWidth: "500px" }}>{option.label}</div>;
  };

  const onClose = () => {
    resetForm();
    setDataLoaded(false); // Reset data loaded state when closing
    onHide();
  };

  const footer = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onClose}
        disabled={formLoading}
      />
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleSubmit}
        disabled={formLoading || !dataLoaded}
      />
    </React.Fragment>
  );

  return (
    <Dialog
      header={currentChecklist ? "Editar Checklist" : "Novo Checklist"}
      visible={visible}
      style={{ width: "80vw" }}
      onHide={onClose}
      footer={footer}
      closeOnEscape={!formLoading}
      closable={!formLoading}
    >
      {loading || !dataLoaded ? (
        <div className="d-flex justify-content-center align-items-center p-5">
          <div className="text-center">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
            <p className="mt-3">Carregando dados do checklist...</p>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-md-6">
              <div className="field">
                <label htmlFor="company">Empresa*</label>
                <Dropdown
                  id="company"
                  value={companyId}
                  options={companies}
                  onChange={(e) => handleChangeSelect(e)}
                  placeholder="Selecione uma empresa"
                  filter
                  className="w-100"
                  disabled={formLoading}
                  optionLabel="label"
                  valueTemplate={
                    companyId &&
                    companies.find((c) => c.value === companyId)?.label
                  }
                  itemTemplate={templateSelectPrime}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="field">
                <label htmlFor="equipment">Equipamento Principal*</label>
                <Dropdown
                  id="equipment"
                  value={equipmentName}
                  options={selectEquipments}
                  onChange={(e) => handleChangeSelectEquipment(e)}
                  placeholder="Selecione um equipamento"
                  filter
                  className="w-100"
                  disabled={disableEquipment || formLoading}
                  optionLabel="label"
                  valueTemplate={equipmentName}
                  itemTemplate={templateSelectPrime}
                />
              </div>
            </div>
          </div>

          <h5>Itens do Checklist</h5>
          <div className="row mb-4">
            {checklistItems.map((item, index) => (
              <div className="col-md-4 mb-3" key={index}>
                <div className="field">
                  <label htmlFor={`item-${index}`}>{item.checklist_item}</label>
                  <Dropdown
                    id={`item-${index}`}
                    value={item.value}
                    options={statusOptions}
                    onChange={(e) => {
                      const newItems = [...checklistItems];
                      newItems[index] = {
                        ...newItems[index],
                        value: e.value,
                        observation: e.value === "Nao Ok" ? "" : null
                      };
                      setChecklistItems(newItems);
                    }}
                    className="w-100"
                    disabled={formLoading}
                    optionLabel="label"
                  />
                  {item.value === "Nao Ok" && (
                    <InputTextarea
                      maxLength={360}
                      value={item.observation || ""}
                      onChange={(e) => {
                        const newItems = [...checklistItems];
                        newItems[index] = {
                          ...newItems[index],
                          observation: e.target.value
                        };
                        setChecklistItems(newItems);
                      }}
                      rows={2}
                      className="w-100 mt-2"
                      placeholder="Observação"
                      disabled={formLoading}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Equipamentos Associados</h5>
            <Button
              icon="pi pi-plus"
              label="Adicionar Equipamento"
              onClick={addAssociatedEquipment}
              disabled={!selectedCompany || formLoading}
            />
          </div>

          {associatedEquipments.map((item, index) => (
            <div className="card mb-3" key={index}>
              <div className="card-body">
                <div className="row d-flex align-items-center">
                  <div className="col-md-4">
                    <div className="field">
                      <label>Equipamento</label>
                      <Dropdown
                        value={item.equipment_id}
                        options={availableEquipments}
                        onChange={(e) =>
                          updateAssociatedEquipment(
                            index,
                            "equipment_id",
                            e.value
                          )
                        }
                        placeholder="Selecione um equipamento"
                        className="w-100"
                        filter
                        disabled={formLoading}
                        optionLabel="label"
                        itemTemplate={templateSelectPrime}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="field">
                      <label>Tipo</label>
                      <Dropdown
                        value={item.type}
                        options={equipmentTypes}
                        onChange={(e) =>
                          updateAssociatedEquipment(index, "type", e.value)
                        }
                        placeholder="Selecione um tipo"
                        className="w-100"
                        disabled={formLoading}
                        optionLabel="label"
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="field">
                      <label>Status</label>
                      <div className="p-field-checkbox mt-3">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`status-${index}`}
                            checked={item.status}
                            onChange={(e) =>
                              updateAssociatedEquipment(
                                index,
                                "status",
                                e.target.checked
                              )
                            }
                            disabled={formLoading}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`status-${index}`}
                          >
                            {item.status ? "Ok" : "Não Ok"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 d-flex align-items-end justify-content-end">
                    <Button
                      icon="pi pi-trash"
                      className="p-button-danger"
                      onClick={() => removeAssociatedEquipment(index)}
                      disabled={formLoading}
                    />
                  </div>
                </div>

                {!item.status && (
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="field">
                        <label>Observação</label>
                        <InputTextarea
                          maxLength={360}
                          value={item.observation}
                          onChange={(e) =>
                            updateAssociatedEquipment(
                              index,
                              "observation",
                              e.target.value
                            )
                          }
                          rows={2}
                          className="w-100"
                          disabled={formLoading}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

          <hr className="my-4" />

          <h5 className="mt-4">Imagens Obrigatórias</h5>
          <div className="row mb-4">
            {Object.entries(images).map(([type, file]) => (
              <div className="col-md-4 mb-3" key={type}>
                <div className="field">
                  <label htmlFor={`image-${type}`}>
                    {type
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </label>
                  <input
                    type="file"
                    id={`image-${type}`}
                    accept="image/jpeg,image/png,image/jpg"
                    onChange={(e) => handleImageChange(type, e.target.files[0])}
                    className="form-control"
                    disabled={formLoading}
                  />
                  {(file || imageUrls[type]) && (
                    <div className="mt-2">
                      <img
                        src={file ? URL.createObjectURL(file) : imageUrls[type]}
                        alt={type}
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Imagens Extras</h5>
            <div>
              {extraImages.length >= 10 && (
                <small className="text-danger d-block mb-1">
                  Limite máximo de 10 imagens atingido
                </small>
              )}
              <Button
                icon="pi pi-plus"
                label="Adicionar Imagem Extra"
                onClick={addExtraImage}
                disabled={formLoading || extraImages.length >= 10}
              />
            </div>
          </div>

          <div className="row mb-4">
            {extraImages.map((file, index) => (
              <div className="col-md-4 mb-3" key={index}>
                <div className="field">
                  <label>Imagem Extra {index + 1}</label>
                  <div className="d-flex align-items-center">
                    <input
                      type="file"
                      accept="image/jpeg,image/png,image/jpg"
                      onChange={(e) =>
                        handleExtraImageChange(index, e.target.files[0])
                      }
                      className="form-control"
                      disabled={formLoading}
                    />
                    {!existingExtraImageIndexes.has(index) && (
                      <Button
                        icon="pi pi-trash"
                        className="p-button-danger ml-2"
                        onClick={() => removeExtraImage(index)}
                        disabled={formLoading}
                      />
                    )}
                  </div>
                  {(file || extraImageUrls[index]) && (
                    <div className="mt-2">
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : extraImageUrls[index]
                        }
                        alt={`Extra ${index + 1}`}
                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ChecklistForm;
