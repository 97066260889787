import React, { useState, useEffect, useRef } from "react";
import Pagination from "@material-ui/lab/Pagination";
import RowsDropdown from "../../components/RowsDropdown";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import api from "../../services/api";
import "./styles.css";

export default function ReportsDrawer({
  visible,
  onHide,
  beginDate,
  endDate,
  techs = []
}) {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [viewType, setViewType] = useState("reports");
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [counter, setCounter] = useState(0);
  const toast = useRef(null);

  const viewOptions = [
    { label: "Relatórios", value: "reports" },
    { label: "Ordens de Serviço", value: "serviceOrders" }
  ];

  useEffect(() => {
    if (visible) {
      if (viewType === "reports") {
        getReports();
      } else {
        getServiceOrders();
      }
    }
  }, [visible, beginDate, endDate, viewType, page, rows]);

  const getReports = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `report/all?page=${page}&rows=${rows}&beginDate=${beginDate}&endDate=${endDate}&multiTechs=${techs.join(
          ","
        )}`
      );
      setReports(response.data.data);
      setCounter(response.data.total);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao carregar relatórios",
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // Smooth scroll to the top of the sidebar content
    const sidebarContent = document.querySelector(".p-sidebar-content");
    if (sidebarContent) {
      sidebarContent.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  };

  const getServiceOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `service_order/by-reports?page=${page}&rows=${rows}&beginDate=${beginDate}&endDate=${endDate}&createdBy=${techs.join(
          ","
        )}`
      );
      setServiceOrders(response.data.data);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao carregar ordens de serviço",
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const header = (
    <div className="table-header">
      <div className="d-flex flex-column">
        <SelectButton
          value={viewType}
          onChange={(e) => setViewType(e.value)}
          options={viewOptions}
          className="mb-3"
        />
        <span>
          {new Date(
            new Date(beginDate).setDate(new Date(beginDate).getDate() + 1)
          ).toLocaleDateString("pt-BR")}{" "}
          até{" "}
          {new Date(
            new Date(endDate).setDate(new Date(endDate).getDate() + 1)
          ).toLocaleDateString("pt-BR")}
        </span>
      </div>
    </div>
  );

  // Reports table components
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-file-pdf"
        className="p-button-rounded p-button-danger p-button-text"
        onClick={() => {
          const isPreventive = !rowData.service_call;
          const url = isPreventive ? "preventive" : "corrective";
          window.open(`/pdf/${url};${rowData.id}`, "_blank").focus();
        }}
      />
    );
  };

  const renderReportNumber = (rowData) => {
    return rowData?.auto_report
      ? String(rowData?.auto_report).padStart(6, 0)
      : rowData.report.padStart(6, 0);
  };

  const renderReportType = (rowData) => {
    if (rowData.service_call) {
      return "Corretiva";
    }
    if (
      rowData.preventive_type === "EmpilhadeiraCombustao" ||
      rowData.preventive_type === "EmpilhadeiraEletricaDeContrapeso"
    ) {
      return "Preventiva Combustão";
    }
    return "Preventiva";
  };

  // Service Orders table components
  const serviceOrderActionTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-file-pdf"
        className="p-button-rounded p-button-danger p-button-text"
        onClick={() =>
          window.open(`/pdf/service_order;${rowData.id}`, "_blank").focus()
        }
      />
    );
  };

  return (
    <>
      <Toast ref={toast} />

      <Sidebar
        visible={visible}
        position="right"
        onHide={onHide}
        className="reports-drawer"
        style={{ width: "70vw" }}
      >
        {viewType === "reports" ? (
          <>
            <DataTable
              value={reports}
              header={header}
              className="p-datatable-responsive-demo"
              loading={loading}
              emptyMessage="Nenhum relatório encontrado"
            >
              <Column
                field="report"
                header="Relatório"
                body={renderReportNumber}
              />
              <Column field="type" header="Tipo" body={renderReportType} />
              <Column field="company.name" header="Empresa" />
              <Column
                field="equipment.equipment"
                header="Equipamento"
                body={(rowData) =>
                  rowData?.service_call?.equipment
                    ? rowData?.service_call?.equipment?.equipment
                    : rowData?.equipment?.equipment
                }
              />
              <Column field="created_by.name" header="Criado por" />
              <Column header="Ações" body={actionBodyTemplate} />
            </DataTable>
            <div className="d-flex justify-content-center align-items-center p-3">
              <div>
                <Pagination
                  className="pagination"
                  color="primary"
                  boundaryCount={1}
                  count={Math.ceil(counter / rows)}
                  onChange={handleChangePage}
                />
              </div>
              <RowsDropdown rows={rows} setRows={setRows} />
            </div>
          </>
        ) : (
          <DataTable
            value={serviceOrders}
            paginator
            rows={10}
            header={header}
            className="p-datatable-responsive-demo"
            loading={loading}
            emptyMessage="Nenhuma ordem de serviço encontrada"
          >
            <Column field="service_number" header="N° da OS" />
            <Column
              field="created_at"
              header="Criado em"
              body={(rowData) =>
                new Date(rowData.created_at).toLocaleDateString("pt-BR")
              }
            />
            <Column field="closed_by_name" header="Fechado por" />
            <Column header="Ações" body={serviceOrderActionTemplate} />
          </DataTable>
        )}
      </Sidebar>
    </>
  );
}
