export const constants = {
  masterEmails: [
    "gracielle.barbosa@grupomov.com.br",
    "gustavo@grupomov.com.br",
    "pericles.code@gmail.com",
    "prievrs@gmail.com",
    "paulorievrsoliveira@gmail.com",
    "jerffesonfranco@gmail.com"
  ]
};
