import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import "./styles.css";

const ChecklistDetails = ({
  visible = false,
  onHide = () => {},
  currentChecklist = null,
  loading = false
}) => {
  function dataConstruct(data) {
    if (!data) {
      return "";
    }

    let splitT = data.split("T");
    let split = splitT[0].split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
  }

  const footer = (
    <React.Fragment>
      <Button
        label="Fechar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHide}
      />
    </React.Fragment>
  );

  const renderImageSection = (images, title) => {
    if (!images || images.length === 0) return null;
    return (
      <div className="mb-4 mt-3">
        <h5 className="mb-3 ">{title}</h5>
        <div className="row">
          {images.map((img, index) => (
            <div className="col-lg-4 col-8 mb-3 text-center" key={index}>
              <div className="card h-100 d-flex flex-column">
                <div className="card-img-top position-relative flex-grow-1 d-flex justify-content-center align-items-center">
                  <Image
                    src={img.url}
                    alt={`${title} ${index + 1}`}
                    preview
                    className="img-fluid rounded"
                    imageStyle={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      maxWidth: "401px",
                      maxHeight: "100%"
                    }}
                  />
                </div>
                <div className="card-footer py-1 text-center mt-auto">
                  <small className="text-muted">
                    {img.type
                      ? img.type
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : ""}
                  </small>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Dialog
      header="Detalhes do Checklist"
      visible={visible}
      style={{ width: "70vw" }}
      onHide={onHide}
      baseZIndex={1000}
      footer={footer}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          carregando
        </div>
      ) : (
        currentChecklist && (
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-md-6">
                <h5>Informações Gerais</h5>
                <div className="detail-item">
                  <strong>Data: </strong>
                  <span>{dataConstruct(currentChecklist.created_at)}</span>
                </div>
                <div className="detail-item">
                  <strong>Empresa: </strong>
                  <span>{currentChecklist.company?.name}</span>
                </div>
                <div className="detail-item">
                  <strong>Equipamento: </strong>
                  <span>{currentChecklist.equipment?.equipment}</span>
                </div>
                <div className="detail-item">
                  <strong>Criado por: </strong>
                  <span>{currentChecklist.created_by?.name}</span>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>

            <h5>Itens do Checklist</h5>
            <div className="row">
              {currentChecklist.checklist_items?.map((item) => (
                <div className="col-md-6 mb-3" key={item.id}>
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title">{item.checklist_item}</h6>
                      <div className="detail-item">
                        <strong>Status: </strong>
                        <span>{item.value}</span>
                      </div>
                      {item.observation && (
                        <div className="detail-item">
                          <strong>Observação: </strong>
                          <span>{item.observation}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="row mt-3">
              <h5>Equipamentos Associados</h5>
              {currentChecklist.checklist_equipments?.map((item) => (
                <div className="col-md-6 mb-3" key={item.id}>
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title">
                        {item.type
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </h6>
                      <div className="detail-item">
                        <strong>Status: </strong>
                        <span>{item.status ? "Ok" : "Não Ok"}</span>
                      </div>
                      <div className="detail-item">
                        <strong>Equipamento: </strong>
                        <span>{item.equipment?.equipment}</span>
                      </div>
                      {item.observation && (
                        <div className="detail-item">
                          <strong>Observação: </strong>
                          <span>{item.observation}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {renderImageSection(
              currentChecklist.images.filter((image) => image.type != "extra"),
              "Imagens do Checklist"
            )}
            {renderImageSection(
              currentChecklist.images.filter((image) => image.type === "extra"),
              "Imagens Extras"
            )}
          </div>
        )
      )}
    </Dialog>
  );
};

export default ChecklistDetails;
