import React, { useEffect, useRef, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Pagination from "@material-ui/lab/Pagination";
import api from "../../../../services/api";
import RowsDropdown from "../../../../components/RowsDropdown";
import { Badge } from "primereact/badge";
import { tableData } from "../../../../contexts/ServiceOrderContext/ConstData";
import { Toast } from "primereact/toast";
import Header from "../../../../components/ContextTable/Header/Header";
import { useAuth } from "../../../../contexts/AuthContext";
import ServiceOrderForm from "../../../../components/ServiceOrdersForm/ServiceOrderForm";
import { useServiceOrder } from "../../../../contexts/ServiceOrderContext";

const DateTypes = [
  {
    name: "created_at",
    label: "Criado em"
  },
  {
    name: "workshop_received_date",
    label: "Recebimento oficina"
  },
  {
    name: "closed_os_date",
    label: "Data de fechamento"
  },
  {
    name: "report_created",
    label: "Relatório criado"
  }
];

const ServiceOrderTable = ({
  equipmentId,
  defaultOrder = -1,
  defaultOrderBy = "created_at",
  type
}) => {
  const dt = useRef(null);
  const context = useServiceOrder();
  const [serviceOrders, setServiceOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(defaultOrder);
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [formModalOpened, setFormModalOpened] = useState(false);
  const [rows, setRows] = useState(100);
  const [selectedItem, setSelectedItem] = useState({});
  const [counter, setCounter] = useState(0);
  const [isActive, setIsActive] = useState(1);
  const [dateType, setDateType] = useState(DateTypes[0].name);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("");

  const toast = useRef(null);
  const { access_type, token } = useAuth();

  const ordenar = (sortField, sortOrder = 1) => {
    setDateType(sortField);
    setOrder(sortOrder);
    setOrderBy(sortField);
    const dateType = DateTypes.find((type) => type.name === sortField);
    // if (context?.setDateType && dateType) {
    //   context?.setDateType(dateType);
    // }
  };

  const fetchServiceOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/service_order/getServiceOrderByEquipment/${equipmentId}`
      );
      setServiceOrders(response.data || []);
      setTotalRecords(response.data.total || 0);
    } catch (error) {
      console.error("Erro ao buscar ordens de serviço:", error);
      setServiceOrders([]);
      setTotalRecords(0);
    } finally {
      setLoading(false);
    }
  };

  const getQueryObject = () => {
    return {
      search: search,
      page: page,
      orderBy: orderBy,
      beginDate: beginDate,
      endDate: endDate,
      status: status,
      rows: rows,
      setCounter,
      order: order,
      id: equipmentId,
      is_active: isActive
    };
  };

  const openFormModal = async (rowData = null) => {
    document.body.style.position = "fixed";
    setFormModalOpened(true);
    if (rowData) {
      await context.setSelectedItem(rowData);
    }
  };

  const hideFormModal = () => {
    document.body.style.position = "relative";
    setFormModalOpened(false);
  };

  const actionTemplate = (rowData, access_type) => {
    return (
      <React.Fragment>
        <Button
          tooltip="Editar"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
          onClick={() => openFormModal(rowData)}
        />

        <Button
          icon="pi pi-th-large"
          tooltip="Qr Code"
          tooltipOptions={{ position: "top" }}
          style={{ backgroundColor: "#1c3424", outline: 0, border: "none" }}
          className="p-button-rounded p-button text-white"
          onClick={() => {
            window
              .open(
                process.env.REACT_APP_LARAVEL_APP +
                  `api/service_order/qr_code?id=${rowData.id}&access_token=${token}`,
                "_blank"
              )
              .focus();
          }}
        />

        <Button
          tooltip="Download relátorio"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-file-pdf"
          style={{
            backgroundColor: "#7f0b0b",
            outline: 0,
            border: "none",
            marginLeft: 6
          }}
          className="p-button-rounded p-button text-white"
          onClick={() =>
            window.open(`/pdf/service_order;${rowData.id}`, "_blank").focus()
          }
        />
      </React.Fragment>
    );
  };

  tableData.find((data) => data.header === "Ações").body = actionTemplate;

  const statusBodyTemplate = (rowData) => {
    if (rowData.status === "Aberto") {
      return (
        <Badge
          style={{ minWidth: 100, backgroundColor: "#9E3131", color: "white" }}
          value={rowData.status}
        />
      );
    } else if (rowData.status === "Em andamento") {
      return (
        <Badge
          style={{ minWidth: 100, color: "white" }}
          severity={"warning"}
          value={rowData.status}
        />
      );
    } else if (rowData.status === "Fechado") {
      return (
        <Badge
          style={{ minWidth: 100, backgroundColor: "#003B17", color: "white" }}
          value={rowData.status}
        />
      );
    } else if (
      rowData.status === "Pendente" ||
      rowData.status === "Pendente peça"
    ) {
      return (
        <Badge
          style={{
            minWidth: 100,
            backgroundColor: "#FF8B00",
            color: "white",
            textWrap: "nowrap"
          }}
          value={
            rowData.status === "Pendente"
              ? "Pendente corretiva"
              : "Pendente peça"
          }
        />
      );
    }
    return (
      <Badge
        style={{ minWidth: 100, backgroundColor: "gray", color: "white" }}
        value={rowData.status}
      />
    );
  };

  useEffect(() => {
    if (equipmentId) {
      fetchServiceOrders();
    }
  }, [equipmentId, page, rows]);

  const formatDate = (dateString) => {
    if (!dateString) return "Sem data";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="card">
      <ServiceOrderForm
        visible={formModalOpened}
        onHide={hideFormModal}
        toast={toast}
        selectedStatus={selectedStatus}
      />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center p-5">
          <div className="text-center">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
            <p className="mt-3">Carregando dados da ordem de serviço...</p>
          </div>
        </div>
      ) : (
        <DataTable
          ref={dt}
          value={serviceOrders}
          dataKey="id"
          rows={rows}
          sortOrder={order}
          sortField={orderBy}
          onSort={({ sortField, sortOrder }) => ordenar(sortField, sortOrder)}
          emptyMessage="Nenhum registro encontrado"
          header={
            <Header
              sortOrder={order}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              setDateType={setDateType}
              dateType={dateType}
              ordenar={ordenar}
              type={type}
              title={"Ordem de serviço"}
              dateTypes={DateTypes.map((type) => ({
                label: type.label,
                value: type.name
              }))}
              {...getQueryObject()}
              setIsActive={(item) => {
                setIsActive(item);
              }}
            />
          }
          className={"p-datatable-responsive-demo"}
        >
          <Column headerStyle={{ width: "3rem" }} />

          {tableData.map((item) => (
            <Column
              headerStyle={item.headerStyle ? item.headerStyle : {}}
              field={item.name ? item.name : null}
              sortable={item.sortable}
              header={item.header}
              {...(item.body
                ? { body: (rowData) => item.body(rowData, access_type) }
                : {})}
            />
          ))}
        </DataTable>
      )}
    </div>
  );
};

export default ServiceOrderTable;
