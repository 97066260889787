import Pagination from "@material-ui/lab/Pagination";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import RowsDropdown from "../../components/RowsDropdown";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";
import BrandModelModal from "./Modals/BrandModelModal";
import { constants } from "../../utils/constants";

const Table = () => {
  const [rows, setRows] = useState(10);
  const { access_type, email } = useAuth();

  const [productDialog, setProductDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const toast = useRef(null);
  const dt = useRef(null);

  let [servico, setServico] = useState("");
  const [equipments, setEquipments] = useState([]);
  const [counter, setCounter] = useState(0);
  const [search, setSearch] = useState("");
  const [is_active, setIsActive] = useState("");

  const [page, setPage] = useState(1);
  const [ordenado, setOrdenado] = useState("");

  const [brandModalVisible, setBrandModalVisible] = useState(false);
  const [modelModalVisible, setModelModalVisible] = useState(false);

  useEffect(() => {
    getEquipments().then(() => setLoading(false));
  }, [page, search, ordenado, is_active, rows]);

  const [sortOrder, setSortOrder] = useState(-1);
  const ordenar = (sortField, sortOrder) => {
    setSortOrder(sortOrder);
    setOrdenado(sortField);
  };

  const handleChangeServico = (option) => {
    setServico(option.value);
  };

  const getEquipments = async () => {
    setLoading(true);

    await api
      .get(
        `equipments?page=${page}&search=${search}&orderBy=${ordenado}&order=${sortOrder}&is_active=${is_active}&rows=${rows}`
      )
      .then((response) => {
        setEquipments(response.data.data);
        setCounter(response.data.total);
      })
      .finally(() => setLoading(false));
  };

  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);

  const [marcasOptions, setMarcasOptions] = useState([
    { value: "", label: "" },
    { value: "", label: "" }
  ]);
  const [modeloOptions, setModeloOptions] = useState([
    { value: "", label: "" },
    { value: "", label: "" }
  ]);

  const getModelos = async () => {
    setLoading(true);

    await api
      .get(`models`)
      .then((response) => {
        setModelos(response.data.data);
        modeloOptions.push(
          ...response.data.map((modelo) => ({
            value: modelo.id,
            label: modelo.name
          }))
        );
        setCounter(response.data.total);
      })
      .finally(() => setLoading(false));
  };

  const getMarcas = async (load = true) => {
    setLoading(load);

    await api
      .get(`brands`)
      .then((response) => {
        setMarcas(response.data.data);
        marcasOptions.push(
          ...response.data.map((marca) => ({
            value: marca.id,
            label: marca.name
          }))
        );
      })
      .finally(() => setLoading(false));
  };

  const openNew = async (rowData) => {
    document.body.style.position = "fixed";

    if (rowData) {
      setPat(rowData?.pat);
      setSerialNumber(rowData?.serial_number);
      setEquipment(rowData?.equipment);
      setBrandName(rowData.brand?.name);
      setModelName(rowData.model?.name);
      setNomeEmpresa(rowData?.company?.name);
      setModel_id(rowData.model?.id);
      setBrand_id(rowData.brand?.id);
      setIdEmpresa(rowData.company?.id);
      setEditingEquipmentId(rowData.id);
      setType(rowData.type);
      setIsFromCostumer(rowData.is_from_costumer ? "Sim" : "Não");
      setServico(rowData?.service_type || "");
    }

    setProductDialog(true);
    await getMarcas();
    await getModelos();
    await getCompanies();
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="d-flex flex-row align-content-center justify-content-between w-100 gap-3">
          <Button
            label={
              access_type === "Admin"
                ? "Novo Equipamento"
                : "Solicitar adição de equipamento"
            }
            style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
            icon="pi pi-plus"
            className="p-button-success"
            onClick={() => {
              openNew(null);
              setIsNew(true);
            }}
          />
          {constants.masterEmails.includes(email) && (
            <Button
              label="Nova Marca"
              icon="pi pi-plus"
              className="p-button-info p-ml-2"
              onClick={() => setBrandModalVisible(true)}
            />
          )}
          {constants.masterEmails.includes(email) && (
            <Button
              label="Novo Modelo"
              icon="pi pi-plus"
              className="p-button-warning p-ml-2"
              onClick={() => setModelModalVisible(true)}
            />
          )}
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    const icon = rowData.is_active ? "pi pi-trash" : "pi pi-angle-double-up";
    const tooltip = rowData.is_active ? "Inativar" : "Ativar";
    const func = rowData.is_active
      ? handleDeactivateEquipment
      : handleActivateEquipment;
    const color = rowData.is_active ? "#9E3131" : "#003B17";
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          tooltip="Editar"
          tooltipOptions={{ position: "top" }}
          className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
          onClick={() => openNew(rowData)}
        />
        <Button
          icon={icon}
          tooltip={tooltip}
          tooltipOptions={{ position: "top" }}
          style={{ backgroundColor: color, outline: 0, border: "none" }}
          className="p-button-rounded p-button-warning text-white p-mr-2 me-2"
          onClick={() => func(rowData.id)}
        />
        {/* <Button icon="pi pi-trash" style={{ backgroundColor: '#9E3131', outline: 0, border: 'none' }} className="p-button-rounded p-button text-white" onClick={() => setEditUserDialog(true) }  /> */}
      </React.Fragment>
    );
  };
  const handleDeactivateEquipment = async (id) => {
    setLoading(true);
    await api
      .get("/equipments/deactivate/" + id)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "",
          detail: "Desativado equipamento com sucesso",
          life: 5000
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao desativar equipamento",
          life: 5000
        });
      })
      .finally(async () => {
        await getEquipments();
      });
  };
  const handleActivateEquipment = async (id) => {
    setLoading(true);
    await api
      .get("/equipments/activate/" + id)
      .then(() => {
        toast.current.show({
          severity: "success",
          summary: "",
          detail: "Ativado equipamento com sucesso",
          life: 5000
        });
      })
      .catch(() => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail: "Erro ao ativar equipamento",
          life: 5000
        });
      })
      .finally(async () => {
        await getEquipments();
      });
  };

  const items = [
    {
      label: "Filtrar",
      items: [
        {
          label: "Ativo",
          command: () => {
            setIsActive(1);
            setPage(1);
          }
        },
        {
          label: "Não Ativo",
          command: () => {
            setIsActive(0);
            setPage(1);
          }
        }
      ]
    }
  ];

  const menu = useRef(null);
  const searchInput = useRef(null);

  const handleSearch = () => {
    const search = searchInput?.current?.value ?? "";
    setSearch(search);
    setPage(1);
  };

  const header = (
    <div className="d-flex flex-row align-content-center justify-content-between">
      <div className="pt-2">
        <h5 className="p-m-0">Todos os Equipamentos</h5>
        <span className="p-input-icon-left d-flex">
          <i className="pi pi-search" />
          <InputText
            type="search"
            ref={searchInput}
            placeholder="Busca..."
            onInput={(e) => {
              if (!e.target.value) {
                setSearch("");
              }
            }}
          />
          <Button
            style={{ color: "white", marginLeft: 8 }}
            icon="pi pi-search"
            onClick={() => handleSearch()}
          />
          {/*<Menu model={items} popup ref={menu} id="popup_menu" />*/}
          {/*<Button icon="pi pi-filter" className={'ms-2 text-white'} onClick={(event) => menu.current.toggle(event)} aria-controls="popup_menu" aria-haspopup />*/}
        </span>
      </div>
    </div>
  );

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleCreateEquipment = async () => {
    if (!brand_id || !model_id || !equipment || !type) {
      toast.current.show({
        severity: "warn",
        summary: "",
        detail: "Os campos marca, modelo, tipo e equipamento são obrigatórios",
        life: 5000
      });
      return;
    }

    let is_from_costumerData = false;
    if (is_from_costumer === "Sim") {
      is_from_costumerData = true;
    }

    const data = {
      pat,
      serial_number,
      equipment,
      brand_id,
      model_id,
      company_id: id_empresa,
      type,
      is_from_costumer: is_from_costumerData,
      service_type: servico
    };
    try {
      const toastDetail = is_new ? "Criado" : "Alterado";

      if (is_new) {
        await api.post("/equipments", data);
      } else if (!is_new && access_type === "Admin") {
        await api.put("/equipments/" + editingEquipmentId, data);
      }

      await getEquipments();
      onHideDialog();
      toast.current.show({
        severity: "success",
        summary: "",
        detail: toastDetail + " equipamento com sucesso",
        life: 5000
      });
    } catch (e) {
      if (e?.response?.data?.message) {
        return toast.current.show({
          severity: "error",
          summary: "",
          detail: e.response.data.message,
          life: 5000
        });
      }

      toast.current.show({
        severity: "error",
        summary: "",
        detail: "Erro ao criar equipamento",
        life: 5000
      });
    }
  };

  const templateSelectPrime = (option) => {
    return <div style={{ maxWidth: "500px" }}>{option.label}</div>;
  };

  const [nomeEmpresa, setNomeEmpresa] = useState("");
  const [id_empresa, setIdEmpresa] = useState("");
  const [empresas, setEmpresas] = useState([]);

  const handleChangeSelect = (selectedOption) => {
    setIdEmpresa(selectedOption.value);
    options.forEach((e) => {
      if (e.value === selectedOption.value) {
        setNomeEmpresa(e.label);
        return;
      }
    });
  };

  const [is_from_costumer, setIsFromCostumer] = useState("Não");

  const handleChangeSelectBelongsToCostumer = (selectedOption) => {
    setIsFromCostumer(selectedOption.value);
  };

  const [brand_id, setBrand_id] = useState(0);
  const [model_id, setModel_id] = useState(0);
  const [equipment, setEquipment] = useState("");
  const [pat, setPat] = useState("");
  const [serial_number, setSerialNumber] = useState("");
  const [type, setType] = useState("");
  const [is_new, setIsNew] = useState(false);
  const [editingEquipmentId, setEditingEquipmentId] = useState(0);

  const [modelName, setModelName] = useState("");
  const [brandName, setBrandName] = useState("");
  const handleChangeSelectBrand = (selectedOption) => {
    setBrand_id(selectedOption.value);
    marcasOptions.forEach((e) => {
      if (e.value === selectedOption.value) {
        setBrandName(e.label);
        return;
      }
    });
  };

  const handleChangeSelectModel = (selectedOption) => {
    setModel_id(selectedOption.value);
    modeloOptions.forEach((e) => {
      if (e.value === selectedOption.value) {
        setModelName(e.label);
        return;
      }
    });
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Exportar"
          style={{ backgroundColor: "#0A073B", outline: 0, border: "none" }}
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  const exportCSV = async () => {
    toast.current.show({
      severity: "info",
      summary: "",
      detail: "Gerando excel, aguarde...",
      life: 5000
    });
    setLoading(true);
    api
      .get(`/equipments/export/excel?search=${search}`, {
        responseType: "blob"
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "equipamentos-" + new Date().getTime() + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "",
          detail:
            "Ocorreu um erro ao gerar excel, contate um administrador: " +
            error.message,
          life: 5000
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [options, setOptions] = useState([{ value: "", label: "" }]);
  let alterEmpresa = [];

  const getCompanies = async () => {
    await api
      .get(`companies/all`)
      .then((response) => {
        setEmpresas(response.data);
        alterEmpresa = response.data;
        options.push(
          ...response.data.map((empresa) => ({
            value: empresa.id,
            label: empresa.name
          }))
        );
      })
      .finally(() => setLoading(false));
  };

  const onHideDialog = () => {
    setProductDialog(false);

    setPat("");
    setSerialNumber("");
    setEquipment("");
    setBrand_id(null);
    setBrandName("");
    setModel_id(null);
    setModelName("");
    setNomeEmpresa("");
    setIdEmpresa(null);
    setIsNew(false);
    setType("");
    setEditingEquipmentId(0);
    setIsFromCostumer("Não");
    setServico("");
    document.body.style.position = null;
  };

  const userDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={onHideDialog}
      />
      <Button
        disabled={loading}
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={handleCreateEquipment}
      />
    </React.Fragment>
  );

  const servicoArr = [
    "",
    "Contrato Locação",
    "Contrato Manutenção Preventiva + Corretiva",
    "Contrato Manutenção Preventiva",
    "Serviço à Faturar",
    "Frota",
    "Garantia",
    "Entrega Técnica"
  ];

  return (
    <>
      <div className="datatable-responsive-demo">
        <Toast ref={toast} position="bottom-right" />

        <div className="card">
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          <DataTable
            ref={dt}
            value={equipments}
            dataKey="id"
            rows={rows}
            header={header}
            className={"p-datatable-responsive-demo"}
            loading={loading}
            selectionMode="single"
            onRowDoubleClick={(e) => history.push(`/equipamentos/${e.data.id}`)}
          >
            <Column headerStyle={{ width: "3rem" }} />
            <Column field="pat" header={"PAT"} />
            <Column field="serial_number" header="Número de série" />
            <Column field="brand.name" header="Marca" />
            <Column field="model.name" header="Modelo" />
            <Column field="equipment" header="Equipamento" />
            <Column field="company.name" header="Empresa" />
            {access_type === "Admin" && (
              <Column header="Ações" body={actionBodyTemplate} />
            )}
          </DataTable>
          <div
            className={"d-flex justify-content-center align-items-center p-3"}
          >
            <div>
              <Pagination
                className="pagination"
                color={"primary"}
                boundaryCount={1}
                count={Math.ceil(counter / rows)}
                onChange={handleChangePage}
              />
            </div>
            <RowsDropdown rows={rows} setRows={setRows} />
          </div>
        </div>
      </div>

      <Dialog
        visible={productDialog}
        style={{ width: 700, marginTop: 50 }}
        modal
        footer={userDialogFooter}
        onHide={onHideDialog}
      >
        <div className={"d-flex flex-column"}>
          <div className={"row"}>
            <div className="col-md-12 d-flex justify-content-md-end justify-content-sm-center align-items-center">
              <form
                className={
                  "d-flex flex-column col-md-12 shadow rounded p-4 justify-content-center align-content-center"
                }
                onSubmit={() => {}}
              >
                <div className={"row pb-1"}>
                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">Equipamento (Descrição): </label>
                    <InputText
                      value={equipment}
                      onChange={(e) => setEquipment(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">PAT: </label>
                    <InputText
                      value={pat}
                      onChange={(e) => setPat(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">Número de série: </label>
                    <InputText
                      value={serial_number}
                      onChange={(e) => setSerialNumber(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-12 pb-3"}>
                    <label htmlFor="Cidade">Tipo: </label>
                    <InputText
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    />
                  </div>

                  <div className={"col-md-6 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">Marca: </label>
                    <Dropdown
                      value={brandName}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={brandName}
                      options={marcasOptions}
                      onChange={handleChangeSelectBrand}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione a marca"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-6 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">Modelo: </label>
                    <Dropdown
                      value={modelName}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={modelName}
                      options={modeloOptions}
                      onChange={handleChangeSelectModel}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione o modelo"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">
                      {access_type !== "Costumer"
                        ? "Equipamento pertence ao cliente?"
                        : "Equipamento pertence a sua empresa?"}
                    </label>
                    <Dropdown
                      value={is_from_costumer}
                      options={["Sim", "Não"]}
                      onChange={handleChangeSelectBelongsToCostumer}
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>

                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Empresa">
                      Empresa (em que a maquina está - se estiver na sua empresa
                      deixe vazio){" "}
                    </label>
                    <Dropdown
                      value={nomeEmpresa}
                      itemTemplate={templateSelectPrime}
                      valueTemplate={nomeEmpresa}
                      options={options}
                      onChange={handleChangeSelect}
                      filter
                      optionLabel={"label"}
                      optionValue={"value"}
                      placeholder="Selecione a empresa"
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>
                  <div className={"col-md-12 pb-3 d-flex flex-column"}>
                    <label htmlFor="Servico">Serviço:</label>

                    <Dropdown
                      value={servico}
                      options={servicoArr}
                      onChange={handleChangeServico}
                      style={{ height: "50px", alignItems: "center" }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
      <BrandModelModal
        visible={brandModalVisible}
        onHide={() => setBrandModalVisible(false)}
        type="brand"
        onSuccess={() => {
          setBrandModalVisible(false);
          getMarcas(); // Atualiza a lista de marcas após criação
        }}
      />

      <BrandModelModal
        visible={modelModalVisible}
        onHide={() => setModelModalVisible(false)}
        type="model"
        onSuccess={() => {
          setModelModalVisible(false);
          getModelos(); // Atualiza a lista de modelos após criação
        }}
      />
    </>
  );
};

export default Table;
