"use client";
import QuantityCard from "../../components/QuantityCard-v2";
import "./dashboard.css";
import { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";
import DateRangeDashboard from "../../components/ToolbarComponents/DateRangeDashboard";
import { useQuery } from "react-query";
import api from "../../services/api";
import Loading from "../../components/Loading";
import { transformDayToHour } from "../../utils/transformer";
import { Button } from "primereact/button";
import { getTimeAgo } from "../../utils/masks";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Skeleton } from "primereact/skeleton";

export default function Dashboard() {
  const currentDate = new Date();
  const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);

  const [selectClient, setSelectClient] = useState(null);
  const [beginDate, setBeginDate] = useState(firstDayOfYear);
  const [endDate, setEndDate] = useState(currentDate);
  const [inputBeginDate, setInputBeginDate] = useState(firstDayOfYear);
  const [inputEndDate, setInputEndDate] = useState(currentDate);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [timeAgo, setTimeAgo] = useState("");

  // Add refresh effect for time ago display
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(getTimeAgo(lastUpdate));
    }, 1000);

    return () => clearInterval(interval);
  }, [lastUpdate]);

  const sumServiceCallsStatus = (status) => {
    if (!selectClient || !selectClient?.equipments) return 0;
    let sum = 0;
    for (const equipment of selectClient?.equipments) {
      sum += equipment.service_calls.filter(
        (call) => call?.status === status
      ).length;
    }
    return sum;
  };

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["companies", beginDate, endDate, selectClient],
    queryFn: async () =>
      await api.get(
        `companies-dashboard?beginDate=${beginDate
          .toISOString()
          .slice(0, 10)}&endDate=${endDate?.toISOString().slice(0, 10) || ""}`
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      if (!selectClient) {
        setSelectClient(data.data[0]);
      } else {
        setSelectClient(
          data.data.find((company) => company.id === selectClient.id)
        );
      }
    }
  });

  const { data: serviceCallsMonthlyData, refetch: refetchMonthly } = useQuery({
    queryKey: ["serviceCallsMonthly", selectClient, endDate],
    queryFn: async () =>
      await api.get(
        `companies-dashboard/month-service-calls?company_id=${
          selectClient?.id
        }&endDate=${endDate.toISOString().slice(0, 10)}`
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  const { data: averageDaysData, refetch: refetchAverage } = useQuery({
    queryKey: ["averageDaysData", selectClient, beginDate, endDate],
    queryFn: async () =>
      await api.get(
        `companies-dashboard/average-days?company_id=${
          selectClient?.id
        }&beginDate=${beginDate.toISOString().slice(0, 10)}&endDate=${endDate
          .toISOString()
          .slice(0, 10)}`
      ),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });

  // Handle refresh button
  const handleRefresh = () => {
    refetch();
    refetchMonthly();
    refetchAverage();
    setLastUpdate(new Date());
  };

  useEffect(() => {
    const statusAberto = sumServiceCallsStatus("Aberto");
    const statusEmAndamento = sumServiceCallsStatus("Em andamento");
    const statusFechado = sumServiceCallsStatus("Fechado");
    const statusCancelado = sumServiceCallsStatus("Cancelado");
    const statusPendente =
      sumServiceCallsStatus("Pendente") +
      sumServiceCallsStatus("Pendente peça");

    const data = {
      labels: ["Aberto", "Em andamento", "Fechado", "Cancelado", "Pendente"],
      datasets: [
        {
          data: [
            statusAberto,
            statusEmAndamento,
            statusFechado,
            statusCancelado,
            statusPendente
          ],
          backgroundColor: [
            "#9E3131",
            "#FFC107",
            "#003B17",
            "#607D8B",
            "#FF8B00"
          ],
          hoverBackgroundColor: [
            "#9E3131",
            "#FFC107",
            "#003B17",
            "#607D8B",
            "#FF8B00"
          ],
          datalabels: {
            formatter: (value) => (value > 0 ? value : ""),
            color: "#fff",
            font: {
              weight: "bold",
              size: 12
            }
          }
        }
      ]
    };
    const options = {
      cutout: "60%",
      plugins: {
        legend: {
          position: "right",
          labels: {
            padding: 20,
            usePointStyle: true,
            pointStyle: "circle"
          }
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              const label = context.label || "";
              const value = context.raw || 0;
              const total = context.dataset.data.reduce(
                (acc, curr) => acc + curr,
                0
              );
              const percentage =
                total > 0 ? Math.round((value / total) * 100) : 0;
              return `${label}: ${value} (${percentage}%)`;
            }
          }
        }
      }
    };
    setChartData(data);
    setChartOptions(options);
  }, [selectClient]);

  const [qtdPatData, setQtdPatData] = useState({});
  const [qtdPatOptions, setQtdPatOptions] = useState({});

  useEffect(() => {
    const data = {
      labels:
        selectClient?.equipments?.map((equipment) => equipment?.pat) ?? [],
      datasets: [
        {
          label: "Quantidade",
          backgroundColor: "#0a7373",
          data:
            selectClient?.equipments?.map(
              (equipment) => equipment?.service_calls?.length
            ) ?? [],
          datalabels: {
            align: "end",
            anchor: "end",
            formatter: (value) => value,
            color: "#333",
            font: {
              weight: "bold",
              size: 12
            }
          }
        }
      ]
    };
    const options = {
      indexAxis: "y",
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          ticks: {
            stepSize: 1,
            callback: function (value) {
              if (Number.isInteger(value)) {
                return value;
              }
              return null;
            }
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        y: {
          grid: {
            display: false,
            drawBorder: false
          }
        }
      }
    };
    // Filter out equipment with 0 service calls
    const filteredData = {
      ...data,
      labels: data.labels.filter(
        (_, index) => data.datasets[0].data[index] > 0
      ),
      datasets: [
        {
          ...data.datasets[0],
          data: data.datasets[0].data.filter((count) => count > 0)
        }
      ]
    };
    setQtdPatData(filteredData);
    setQtdPatOptions(options);
  }, [selectClient]);

  const companies = data?.data ?? [];

  const [qtdChamadosData, setQtdChamadosData] = useState({});
  const [qtdChamadosOptions, setQtdChamadosOptions] = useState({});

  useEffect(() => {
    if (!serviceCallsMonthlyData) return;

    const currentMonth = new Date().getMonth();

    const filteredMonthlyData = serviceCallsMonthlyData?.data?.filter(
      (i, index) => {
        return index <= currentMonth;
      }
    );

    const data = {
      labels: filteredMonthlyData?.map((i) => i?.month?.substr(0, 3)),
      datasets: [
        {
          label: "Quantidade",
          data: filteredMonthlyData?.map((i) => i?.count),
          fill: true,
          tension: 0.5,
          borderColor: "#eca81f",
          backgroundColor: "rgba(255, 167, 38, 0.2)",
          datalabels: {
            align: "top",
            anchor: "end",
            formatter: (value) => value,
            color: "#333",
            font: {
              weight: "bold",
              size: 12
            }
          }
        }
      ]
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        x: {
          ticks: {
            color: "black"
          },
          grid: {
            display: false
          }
        },
        y: {
          ticks: {
            color: "black"
          },
          grid: {
            color: "rgba(0, 0, 0, 0.05)"
          }
        }
      }
    };

    setQtdChamadosData(data);
    setQtdChamadosOptions(options);
  }, [serviceCallsMonthlyData]);

  const sumServiceCallsLength = () => {
    if (!selectClient || !selectClient?.equipments) return 0;
    let sum = 0;
    for (const equipment of selectClient?.equipments) {
      sum += equipment.service_calls.length;
    }
    return sum;
  };

  const CardSkeleton = () => (
    <div className="quantity-card skeleton-card">
      <div className="card-icon skeleton-icon">
        <Skeleton width="100%" height="100%" borderRadius="12px" />
      </div>
      <div className="card-content" style={{ width: "100%" }}>
        <Skeleton width="70%" height="30px" className="mb-2" />
        <Skeleton width="40%" height="16px" />
      </div>
    </div>
  );

  const ChartSkeleton = ({ height = "300px" }) => (
    <div className="chart-card">
      <div className="chart-header">
        <Skeleton width="180px" height="20px" />
        <Skeleton width="120px" height="16px" />
      </div>
      <div className="chart-content" style={{ height }}>
        <div className="skeleton-chart">
          <Skeleton width="100%" height="100%" />
        </div>
      </div>
    </div>
  );

  const TableSkeleton = () => (
    <div className="equipment-content">
      <div className="equipment-table-container">
        <Skeleton width="100%" height="40px" className="mb-2" />
        {[...Array(5)].map((_, i) => (
          <Skeleton key={i} width="100%" height="36px" className="mb-2" />
        ))}
      </div>
    </div>
  );

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h2 className="dashboard-title">Dashboard do Cliente</h2>
        <div className="dashboard-refresh">
          <div className="refresh-info">
            <span className="last-update">Atualizar:</span>
            <Button
              icon="pi pi-refresh"
              className="refresh-button p-button-outlined"
              onClick={handleRefresh}
              loading={isLoading}
              tooltip="Atualizar dados"
              tooltipOptions={{ position: "bottom" }}
            />
          </div>
        </div>
      </div>

      <div className="dashboard-wrapper">
        <div className="dashboard-sidebar">
          <div className="filter-card">
            <h3 className="filter-title">Filtros</h3>
            <div className="filter-content">
              <label>Período</label>
              <DateRangeDashboard
                inputBeginDate={inputBeginDate}
                setInputBeginDate={setInputBeginDate}
                beginDate={beginDate}
                setBeginDate={setBeginDate}
                endDate={endDate}
                setEndDate={setEndDate}
                inputEndDate={inputEndDate}
                setInputEndDate={setInputEndDate}
              />

              <label className="mt-3">Cliente</label>
              {isLoading ? (
                <Skeleton width="100%" height="38px" borderRadius="6px" />
              ) : (
                <Dropdown
                  className="w-100"
                  value={selectClient}
                  onChange={(e) => setSelectClient(e.value)}
                  options={companies}
                  optionLabel="name"
                  filter
                  placeholder="Selecione um cliente"
                />
              )}
            </div>
          </div>

          <div className="equipment-card">
            <h3 className="equipment-title">Equipamentos</h3>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <div className="equipment-content">
                <div className="equipment-table-container">
                  <table className="equipment-table">
                    <thead>
                      <tr>
                        <th>Equipamento</th>
                        <th>Chamados</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectClient?.equipments
                        ?.slice()
                        .sort(
                          (a, b) =>
                            (b.service_calls?.length ?? 0) -
                            (a.service_calls?.length ?? 0)
                        )
                        .map((equipment, index) => (
                          <tr key={index}>
                            <td>{equipment?.equipment}</td>
                            <td>{equipment?.service_calls?.length ?? 0}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="dashboard-content">
          <div className="dashboard-cards">
            {isLoading ? (
              <>
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
              </>
            ) : (
              <>
                <QuantityCard
                  label="Total de Chamados"
                  color="#2196F3"
                  value={sumServiceCallsLength()}
                  icon="pi pi-ticket"
                  description="Total de chamados abertos para o cliente"
                />
                <QuantityCard
                  label="Prazo Médio Atendimento"
                  color="#9C27B0"
                  value={
                    averageDaysData?.data?.average_days_to_first_report
                      ? `${transformDayToHour(
                          averageDaysData?.data?.average_days_to_first_report
                        )}h`
                      : "0h"
                  }
                  icon="pi pi-stopwatch"
                  description="Tempo médio até o primeiro atendimento"
                />
                <QuantityCard
                  label="Prazo Médio Resolução"
                  color="#4CAF50"
                  value={
                    averageDaysData?.data?.average_days_to_close
                      ? `${transformDayToHour(
                          averageDaysData?.data?.average_days_to_close
                        )}h`
                      : "0h"
                  }
                  icon="pi pi-check-circle"
                  description="Tempo médio até o fechamento do chamado"
                />
              </>
            )}
          </div>

          <div className="chart-grid">
            {isLoading ? (
              <>
                <ChartSkeleton height="350px" />
                <ChartSkeleton />
                <ChartSkeleton height="300px" />
              </>
            ) : (
              <>
                <div className="chart-card">
                  <div className="chart-header">
                    <h3>Status dos Chamados</h3>
                    <div className="chart-legend">
                      <i
                        className="pi pi-chart-pie"
                        style={{ color: "#9E3131" }}
                      />
                      <span>Distribuição por status</span>
                    </div>
                  </div>
                  <div className="chart-content chart-content-donut">
                    <Chart
                      type="doughnut"
                      data={chartData}
                      options={chartOptions}
                      plugins={[ChartDataLabels]}
                    />
                  </div>
                </div>

                <div className="chart-card">
                  <div className="chart-header">
                    <h3>Chamados por Equipamento</h3>
                    <div className="chart-legend">
                      <i className="pi pi-cog" style={{ color: "#0a7373" }} />
                      <span>Quantidade por equipamento</span>
                    </div>
                  </div>
                  <div className="chart-content">
                    <Chart
                      type="bar"
                      data={qtdPatData}
                      options={qtdPatOptions}
                      plugins={[ChartDataLabels]}
                    />
                  </div>
                </div>

                <div className="chart-card full-width">
                  <div className="chart-header">
                    <h3>Chamados por Mês</h3>
                    <div className="chart-legend">
                      <i
                        className="pi pi-calendar"
                        style={{ color: "#eca81f" }}
                      />
                      <span>Evolução mensal</span>
                    </div>
                  </div>
                  <div className="chart-content">
                    <Chart
                      type="line"
                      data={qtdChamadosData}
                      options={qtdChamadosOptions}
                      plugins={[ChartDataLabels]}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
