import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ShowPdf() {
  const { token } = useAuth();
  const { typeWithId } = useParams();
  const type = typeWithId.split(";")[0];
  const id = typeWithId.split(";")[1];
  const ocamentoType = typeWithId.split(";")[2];
  document.body.style.overflowY = "hidden";
  console.log(type, id, ocamentoType);

  return (
    <div
      style={{
        height: "90vh",
        width: "98vw"
      }}
    >
      <embed
        src={`${process.env.REACT_APP_LARAVEL_APP}api/report/${type}/pdf/${id}?access_token=${token}&type=${ocamentoType}`}
        width="100%"
        height="100%"
        type="application/pdf"
        allowFullScreen={true}
      />
    </div>
  );
}
