import { ptBR } from "@material-ui/core/locale";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Drawer from "../components/Drawer";
import { useAuth } from "../contexts/AuthContext";
import Pendentes from "../pages/Chamados/Pendentes";
import ChamadosTable from "../pages/Chamados/Table";
import Corretivas from "../pages/Corretivas";
import CompanyMap from "../pages/Empresas/Map/CompanyMap";
import CompaniesTable from "../pages/Empresas/Table";
import Equipamentos from "../pages/Equipamentos";
import EquipmentDetail from "../pages/Equipamentos/Details";
import PendingEquipments from "../pages/Equipamentos/Pendentes";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Parts from "../pages/Parts/Table";
import PreventivesEletrica from "../pages/Preventives/Eletrica";
import Profile from "../pages/Profile";
import ServiceOrderTable from "../pages/ServiceOrder/Table";
import LogsTable from "../pages/Logs/Table";
import Users from "../pages/Users/Table";
import ShowPdf from "../pages/PDF";
import Orcamento from "../pages/Orcamento";
import Dashboard from "../pages/Relatorios/Dashboard";
import ValidarRelatorio from "../pages/Relatorios/ValidarRelatorio";
import AllReports from "../pages/Reports";
import Checklists from "../pages/Checklists";

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#0A073B" }
    }
  },
  ptBR
);

export default function PrivateRoutes() {
  const { access_type } = useAuth();

  const adminRoutes = () => {
    return (
      <Switch>
        <Route path="/" exact component={Home} />

        <Route path="/chamados" exact component={ChamadosTable} />
        <Route path="/profile" exact component={Profile} />

        <Route path="/users" component={Users} />
        <Route path="/empresas" exact component={CompaniesTable} />
        <Route path="/equipamentos" exact component={Equipamentos} />
        <Route
          path="/equipamentos-pendentes"
          exact
          component={PendingEquipments}
        />
        <Route path="/equipamentos/:pat" exact component={EquipmentDetail} />
        <Route path="/chamados/pendentes" exact component={Pendentes} />
        <Route path="/ordens-de-servico" exact component={ServiceOrderTable} />
        <Route path="/checklists" exact component={Checklists} />

        <Route path="/preventivas" exact component={PreventivesEletrica} />
        <Route path="/corretivas" exact component={Corretivas} />
        <Route path="/relatorios" exact component={AllReports} />

        <Route path="/pecas" exact component={Parts} />
        <Route path="/mapa-empresas" exact component={CompanyMap} />
        <Route path="/logs/:user_id" exact component={LogsTable} />
        <Redirect from="/login" to="/" />
        <Route path="/pdf/:typeWithId" exact component={ShowPdf} />
        <Route path="/orcamento" exact component={Orcamento} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route
          path="/validar-relatorio/:token"
          exact
          component={ValidarRelatorio}
        />
        <Route component={NotFound} />
      </Switch>
    );
  };

  const userRoutes = () => {
    return (
      <Switch>
        <Route path="/" exact component={Home} />

        <Route path="/chamados" exact component={ChamadosTable} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/equipamentos" exact component={Equipamentos} />

        <Redirect from="/login" to="/" />
        <Route path="/equipamentos/:pat" exact component={EquipmentDetail} />

        <Route path="/preventivas" exact component={PreventivesEletrica} />
        <Route path="/corretivas" exact component={Corretivas} />
        <Route path="/relatorios" exact component={AllReports} />

        <Route path="/pdf/:typeWithId" exact component={ShowPdf} />
        <Route
          path="/validar-relatorio/:token"
          exact
          component={ValidarRelatorio}
        />
        <Route component={NotFound} />
      </Switch>
    );
  };

  const techRoutes = () => {
    return (
      <Switch>
        <Route path="/" exact component={Home} />

        <Route path="/chamados" exact component={ChamadosTable} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/equipamentos" exact component={Equipamentos} />

        <Redirect from="/login" to="/" />
        <Route path="/equipamentos/:pat" exact component={EquipmentDetail} />

        <Route path="/preventivas" exact component={PreventivesEletrica} />
        <Route path="/corretivas" exact component={Corretivas} />
        <Route path="/relatorios" exact component={AllReports} />

        <Route path="/pdf/:typeWithId" exact component={ShowPdf} />
        <Route
          path="/validar-relatorio/:token"
          exact
          component={ValidarRelatorio}
        />
        <Route component={NotFound} />
      </Switch>
    );
  };

  const routes = () => {
    if (access_type === "Admin") {
      return adminRoutes();
    } else if (access_type === "Costumer") {
      return userRoutes();
    } else if (access_type === "Tech") {
      return techRoutes();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Drawer>{routes()}</Drawer>
      </BrowserRouter>
    </ThemeProvider>
  );
}
