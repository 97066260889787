import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import api from "../../../../services/api";
import Pagination from "@material-ui/lab/Pagination";
import RowsDropdown from "../../../../components/RowsDropdown";
import { useAuth } from "../../../../contexts/AuthContext";
import Header from "../../../../components/ContextTable/Header/Header";
import ChecklistDetails from "../../../../components/ChecklistDetails";

const DateTypes = [
  {
    name: "created_at",
    label: "Criado em"
  }
];

const EquipmentChecklistTable = ({
  equipmentId,
  defaultOrder = -1,
  defaultOrderBy = "created_at",
  type
}) => {
  const dt = useRef(null);
  const toast = useRef(null);
  const { access_type, token } = useAuth();

  const [checklists, setChecklists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [order, setOrder] = useState(defaultOrder);
  const [beginDate, setBeginDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rows, setRows] = useState(10);
  const [counter, setCounter] = useState(0);
  const [isActive, setIsActive] = useState(1);
  const [dateType, setDateType] = useState(DateTypes[0].name);
  const [totalRecords, setTotalRecords] = useState(0);
  const [checklistDetailModal, setChecklistDetailModal] = useState(false);
  const [currentChecklist, setCurrentChecklist] = useState(null);

  const ordenar = (sortField, sortOrder = 1) => {
    setDateType(sortField);
    setOrder(sortOrder);
    setOrderBy(sortField);
  };

  const fetchChecklists = async () => {
    setLoading(true);
    try {
      const response = await api.get("/checklist", {
        params: {
          equipmentId,
          page: page,
          limit: rows,
          search: search,
          beginDate: beginDate,
          endDate: endDate,
          sortField: orderBy,
          sortOrder: order
        }
      });
      setChecklists(response.data.data || []);
      setTotalRecords(response.data.total || 0);
      setCounter(response.data.total || 0);
    } catch (error) {
      console.error("Erro ao buscar checklists:", error);
      setChecklists([]);
      setTotalRecords(0);
      setCounter(0);
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao carregar checklists para este equipamento",
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  const getQueryObject = () => {
    return {
      search: search,
      page: page,
      orderBy: orderBy,
      beginDate: beginDate,
      endDate: endDate,
      rows: rows,
      setCounter,
      order: order,
      id: equipmentId,
      is_active: isActive
    };
  };

  useEffect(() => {
    if (equipmentId) {
      fetchChecklists();
    }
  }, [equipmentId, page, rows, orderBy, order, beginDate, endDate, search]);

  function formatDate(dateString) {
    if (!dateString) {
      return "";
    }

    let splitT = dateString.split("T");
    let split = splitT[0].split("-");
    return split[2] + "/" + split[1] + "/" + split[0];
  }

  const showChecklistDetails = (rowData) => {
    setCurrentChecklist(null);
    setChecklistDetailModal(true);
    setLoading(true);
    api
      .get(`/checklist/${rowData.id}`)
      .then((response) => {
        setCurrentChecklist(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: "Erro ao carregar detalhes do checklist",
          life: 3000
        });
        setLoading(false);
        setChecklistDetailModal(false);
      });
  };

  const actionTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-eye"
          tooltip="Visualizar"
          tooltipOptions={{ position: "top" }}
          style={{
            backgroundColor: "#0A073B",
            outline: 0,
            border: "none"
          }}
          className="p-button-rounded p-button text-white p-mr-2 me-2"
          onClick={() => showChecklistDetails(rowData)}
        />

        <Button
          icon="pi pi-file-pdf"
          tooltip="PDF"
          tooltipOptions={{ position: "top" }}
          style={{
            backgroundColor: "#7f0b0b",
            outline: 0,
            border: "none",
            marginLeft: 6
          }}
          className="p-button-rounded p-button text-white"
          onClick={() =>
            window.open(`/pdf/checklist;${rowData.id}`, "_blank").focus()
          }
        />
      </React.Fragment>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className="card">
      <Toast ref={toast} />

      <ChecklistDetails
        visible={checklistDetailModal}
        onHide={() => setChecklistDetailModal(false)}
        currentChecklist={currentChecklist}
        loading={loading}
      />

      <DataTable
        ref={dt}
        value={checklists}
        dataKey="id"
        rows={rows}
        sortOrder={order}
        sortField={orderBy}
        onSort={({ sortField, sortOrder }) => ordenar(sortField, sortOrder)}
        emptyMessage="Nenhum checklist encontrado para este equipamento"
        header={
          <Header
            sortOrder={order}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            setDateType={setDateType}
            dateType={dateType}
            ordenar={ordenar}
            type={type}
            title={"Checklists do Equipamento"}
            dateTypes={DateTypes.map((type) => ({
              label: type.label,
              value: type.name
            }))}
            {...getQueryObject()}
            setIsActive={(item) => {
              setIsActive(item);
            }}
          />
        }
        className={"p-datatable-responsive-demo"}
        loading={loading}
      >
        <Column headerStyle={{ width: "3rem" }} />
        <Column
          headerStyle={{ width: "10rem" }}
          field="created_at"
          sortable={true}
          header="Data"
          body={(rowData) => formatDate(rowData.created_at)}
        />
        <Column
          field="company.name"
          header="Empresa"
          body={(rowData) => rowData.company?.name || "N/A"}
        />
        <Column
          field="created_by.name"
          header="Criado por"
          body={(rowData) => rowData.created_by?.name || "N/A"}
        />
        <Column
          header="Ações"
          body={actionTemplate}
          headerStyle={{ width: "12rem" }}
        />
      </DataTable>

      <div className={"d-flex justify-content-center align-items-center p-3"}>
        <div>
          <Pagination
            className="pagination"
            color={"primary"}
            boundaryCount={1}
            count={Math.ceil(counter / rows)}
            page={page}
            onChange={handleChangePage}
          />
        </div>
        <RowsDropdown rows={rows} setRows={setRows} />
      </div>
    </div>
  );
};

export default EquipmentChecklistTable;
